import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import module from "../package.json";
import { USER_DATA_KEY } from "../utils/constants";
import { storageLoad } from "../utils/storage";
import { UserData } from "../stores/account-store/account-store.model";

if (!(Bugsnag as any)._client && process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact(React)],
    appVersion: !module ? "" : module.version,
    enabledReleaseStages: [process.env.NEXT_PUBLIC_ENVIRONMENT || "production"],
    onError: async function (event) {
      const savedUser: UserData = await storageLoad(USER_DATA_KEY);
      event.setUser(savedUser?.id, null, savedUser?.phone);
    },
  });
}

export default Bugsnag;
