/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from "react";
import { SnackbarProps } from ".";

export const initialContent: SnackbarProps = {
  visible: false,
  timeout: 5000,
};

export const SnackbarContext = createContext({
  content: initialContent,
  hideSnackbar: () => null,
  showSnackbar: (_: SnackbarProps) => null,
});
