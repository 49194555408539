import classNames from "classnames";
import React, { useContext } from "react";
import { FiX, FiXCircle } from "react-icons/fi";
import { SnackbarContext, SnackbarProps } from ".";
import styles from "./snackbar.module.css";

export const Snackbar: React.FunctionComponent<SnackbarProps> = () => {
  const {
    content: { visible, type, text, subtext, withButton, buttonComponent },
    hideSnackbar,
  } = useContext(SnackbarContext);

  if (!visible) {
    return null;
  }

  return (
    <div className={classNames(styles.container)}>
      <div
        className={classNames(styles.snackbar, {
          [styles.success]: type === "success",
          [styles.danger]: type === "danger",
          [styles.info]: type === "info",
          [styles.warning]: type === "warning",
        })}
      >
        {withButton ? (
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <FiXCircle size={24} className="text-red shrink-0 mr-4" />
              <div className="flex flex-col">
                <span className="font-bold">{text}</span>
                <span className="font-medium leading-tight whitespace-pre-line">
                  {subtext}
                </span>
              </div>
            </div>
            {buttonComponent}
          </div>
        ) : (
          <div className="flex justify-between items-center">
            <span>{text}</span>
            <FiX
              size={18}
              onClick={hideSnackbar}
              className="cursor-pointer"
              id="snackbar-close-btn"
              data-testid="snackbar-close-btn"
            />
          </div>
        )}
      </div>
    </div>
  );
};
