import { cast, Instance, types } from "mobx-state-tree";
import {
  CoverageAreaDistrictObject,
  CoverageAreaObject,
  ResellerCityObject,
  ResellerDistrictObject,
} from "../../utils/interfaces";

export const ResellerCityModel = types.model("ResellerCityModel").props({
  id: types.number,
  name: types.string,
});

export type ResellerCity = Instance<typeof ResellerCityModel>;

export function mapResellerCityObjectToResellerCity(
  obj: ResellerCityObject
): ResellerCity {
  return {
    id: obj.id,
    name: obj.name,
  };
}

export const ResellerDistrictModel = types
  .model("ResellerDistrictModel")
  .props({
    id: types.number,
    name: types.string,
    cityId: types.number,
    cityName: types.string,
  });

export type ResellerDistrict = Instance<typeof ResellerDistrictModel>;

export function mapResellerDistrictObjectToResellerDistrict(
  obj: ResellerDistrictObject
): ResellerDistrict {
  return {
    id: obj.id,
    name: obj.name,
    cityId: obj.city_id,
    cityName: obj.City?.name,
  };
}

export const CoverageAreaDistrictModel = types
  .model("CoverageAreaDistrictModel")
  .props({
    id: types.number,
    stateId: types.number,
    stateName: types.string,
    cityId: types.number,
    cityName: types.string,
    districtId: types.number,
    districtName: types.string,
  });

export type CoverageAreaDistrict = Instance<typeof CoverageAreaDistrictModel>;

export function mapCoverageAreaDistrictObjectToCoverageAreaDistrict(
  obj: CoverageAreaDistrictObject
): CoverageAreaDistrict {
  return {
    id: obj.id,
    stateId: obj.state_id,
    stateName: obj.State.name,
    cityId: obj.city_id,
    cityName: obj.City.name,
    districtId: obj.district_id,
    districtName: obj.District.name,
  };
}

export const CoverageAreaModel = types.model("CoverageAreaModel").props({
  id: types.identifier,
  name: types.string,
  phone: types.string,
  address: types.string,
  status: types.boolean,
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
  areaDistricts: types.maybeNull(types.array(CoverageAreaDistrictModel)),
  locationSource: types.maybeNull(types.string),
  isTempoSkipKyc: types.boolean,
  isTempoSkipKycTrading: types.boolean,
});

export type CoverageArea = Instance<typeof CoverageAreaModel>;

export function mapCoverageAreaObjectToCoverageArea(
  obj: CoverageAreaObject
): CoverageArea {
  return {
    id: obj.id,
    name: obj.name,
    phone: obj.phone,
    address: obj.address,
    status: obj.status,
    latitude: obj.latitude ?? null,
    longitude: obj.longitude ?? null,
    areaDistricts: cast(
      obj.CoverageAreaDistricts?.length
        ? obj.CoverageAreaDistricts?.map((d) =>
            mapCoverageAreaDistrictObjectToCoverageAreaDistrict(d)
          )
        : null
    ),
    locationSource: null,
    isTempoSkipKyc: obj.is_tempo_skip_kyc ?? null,
    isTempoSkipKycTrading: obj.is_tempo_skip_kyc_trading ?? null,
  };
}
