import { cast, Instance, types } from "mobx-state-tree";
import {
  BuyerAccountObject,
  GetOrganicStatusResponse,
  SpecialCodeHistoryObj,
} from "../../utils/interfaces";
import { SalesModel } from "../seller-store/seller-store.model";

export const UserDataModel = types.model("UserDataModel").props({
  id: types.string,
  phone: types.string,
  isTradingBuyer: types.boolean,
});
export type UserData = Instance<typeof UserDataModel>;

export const SpecialCodeHistoryModel = types
  .model("SpecialCodeHistoryModel")
  .props({
    specialCode: types.string,
  });
export type SpecialCodeHistory = Instance<typeof SpecialCodeHistoryModel>;
export function mapSpecialCodeHistoriesObjToSpecialCodeHistories(
  histories: SpecialCodeHistoryObj[]
): SpecialCodeHistory[] {
  return histories.map((obj) => ({
    specialCode: obj.special_code,
  }));
}

export const KYCModel = types.model("KYCModel").props({
  salt: types.string,
  identity_number: types.string,
  full_name: types.string,
  place_of_birth: types.string,
  date_of_birth: types.Date,
  blood_type: types.string,
  address: types.string,
  religion: types.string,
  marital_status: types.string,
  rt: types.string,
  rw: types.string,
  administrative_village: types.string,
  district: types.string,
  other_name: types.string,
  id: types.string,
  user_id: types.string,
  store_status: types.string,
  status: types.string,
  verification_stage: types.string,
});

export type KYC = Instance<typeof KYCModel>;

export const BuyerAccountModel = types.model("BuyerAccountModel").props({
  id: types.string,
  username: types.maybeNull(types.string),
  phone: types.string,
  name: types.string,
  referralCode: types.string,
  verified: types.boolean,
  createdAt: types.string,
  address: types.maybeNull(types.string),
  additionalAddress: types.maybeNull(types.string),
  referredBy: types.maybeNull(types.string),
  pictureUrl: types.maybeNull(types.string),
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
  selectedSales: types.maybeNull(SalesModel),
  coverageAreaId: types.maybeNull(types.string),
  Kyc: types.maybeNull(KYCModel),
  isBuyerStoreVerified: types.maybeNull(types.boolean),
  isBuyerStoreOnProcessed: types.maybeNull(types.boolean),
  isBuyerIdentityVerified: types.maybeNull(types.boolean),
  isTempoSkipKyc: types.maybeNull(types.boolean),
  trading: types.boolean,
  tempoMaxOrderLimit: types.maybeNull(types.number),
  tradingTempoMaxOrderLimit: types.maybeNull(types.number),
  hasReferralCategory: types.boolean,
  specialCodeHistories: types.maybe(types.array(SpecialCodeHistoryModel)),
});

export type BuyerAccount = Instance<typeof BuyerAccountModel>;

export function mapBuyerAccountObjectToBuyerAccount(
  obj: BuyerAccountObject
): BuyerAccount {
  return {
    id: obj.id,
    username: obj.username,
    phone: obj.phone,
    name: obj.name,
    referralCode: obj.referral_code,
    verified: obj.verified,
    createdAt: obj.created_at,
    address: obj.address,
    additionalAddress: obj.additional_address,
    referredBy: obj.referred_by,
    pictureUrl: obj.picture_url,
    latitude: obj.latitude,
    longitude: obj.longitude,
    selectedSales: obj.selectedSales || null,
    coverageAreaId: obj.coverage_area_id,
    Kyc: obj.Kyc,
    isBuyerStoreVerified:
      obj.Kyc && obj.Kyc?.store_status
        ? obj.Kyc?.store_status === "verified"
        : null,
    isBuyerStoreOnProcessed:
      obj.Kyc && obj.Kyc?.store_status
        ? obj.Kyc?.store_status === "on_processed"
        : null,
    isBuyerIdentityVerified:
      obj.Kyc && obj.Kyc?.status && obj.Kyc?.verification_stage
        ? obj.Kyc?.status === "verified" &&
          obj.Kyc?.verification_stage === "selfie_ktp_verified"
        : null,
    isTempoSkipKyc: obj.is_tempo_skip_kyc,
    trading: obj.trading,
    tempoMaxOrderLimit: obj.tempo_max_order_limit || null,
    tradingTempoMaxOrderLimit: obj.trading_tempo_max_order_limit || null,
    hasReferralCategory: obj.allow_filtered_product_categories || false,
    specialCodeHistories: cast(
      obj.SpecialCodeHistories?.length
        ? mapSpecialCodeHistoriesObjToSpecialCodeHistories(
            obj.SpecialCodeHistories
          )
        : []
    ),
  };
}

// UNUSED for now, save memory
// const SellerStaffModel = types.model('SellerStaffModel')
//   .props({
//     id: types.string,
//     username: types.string,
//     roleId: types.number,
//     sellerId: types.string,
//     status: types.boolean,
//   })

// export type SellerStaff = Instance<typeof SellerStaffModel>

// UNUSED for now, save memory
// const BuyerDetailModel = types.model('BuyerDetailModel')
//   .props({
//     id: types.string,
//     name: types.string,
//     username: types.string,
//     phone: types.string,
//     type: types.string,
//     referred_by: types.string,
//     blocked_at: types.Date,
//     organic: types.boolean,
//     SellerStaff: types.maybeNull(SellerStaffModel),
//   })

// export type BuyerDetail = Instance<typeof BuyerDetailModel>

// export function mapBuyerDetailObjectToBuyerDetail(obj: BuyerDetailResponse): BuyerDetail {
//   return {
//     id: obj?.id,
//     name: obj.name,
//     username: obj?.username,
//     phone: obj?.phone,
//     type: obj?.type,
//     referred_by: obj?.referred_by,
//     blocked_at: obj?.blocked_at,
//     organic: obj?.organic,
//     SellerStaff: cast(obj?.SellerStaff),
//   }
// }

export const OrganicStatusModel = types.model("OrganicStatusModel").props({
  organic: types.boolean,
  countOrderWithoutSalesAssigned: types.number,
  countOrderWithSalesAssigned: types.number,
  salesId: types.string,
  salesUsername: types.string,
  // BuyerDetail: types.maybeNull(BuyerDetailModel)
});

export type OrganicStatus = Instance<typeof OrganicStatusModel>;

export function mapOrganicStatusObjectToOrganicStatus(
  obj: GetOrganicStatusResponse
): OrganicStatus {
  return {
    organic: obj?.organic,
    countOrderWithoutSalesAssigned: obj?.count_order_without_sales_assigned,
    countOrderWithSalesAssigned: obj?.count_order_with_sales_assigned,
    salesId: obj?.sales_id,
    salesUsername: obj?.sales_username,
    // BuyerDetail: mapBuyerDetailObjectToBuyerDetail(obj?.BuyerDetail) // UNUSED, save memory
  };
}
