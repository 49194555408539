import React from "react";

export enum ModalType {
  locationPicker,
  cityPicker,
  mapPicker,
}

export enum LocationSource {
  dropdown = "DROPDOWN",
  map = "MAP",
}

interface ILocationPickerContext {
  modalType: ModalType;
  setModalType: (type: ModalType) => void;
  onSubmit: () => void;
  onClose: () => void;
}

export const LocationPickerContext =
  React.createContext<ILocationPickerContext | null>(null);
LocationPickerContext.displayName = "LocationPickerContext";

export const LocationPickerContextProvider = LocationPickerContext.Provider;
