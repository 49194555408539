import { Api } from "../services/api";
import AccountStore from "./account-store/account-store";
import CartStore from "./cart-store/cart-store";
import CoverageAreaStore from "./coverage-area-store/coverage-area-store";
import OrderStore from "./order-store/order-store";
import ProductStore from "./product-store/product-store";
import SellerStore from "./seller-store/seller-store";
import VoucherStore from "./voucher-store/voucher-store";

class RootStore {
  api: Api;
  accountStore: AccountStore;
  cartStore: CartStore;
  coverageAreaStore: CoverageAreaStore;
  orderStore: OrderStore;
  productStore: ProductStore;
  sellerStore: SellerStore;
  voucherStore: VoucherStore;

  constructor() {
    this.api = new Api();
    this.api.setup();

    this.accountStore = new AccountStore(this);
    this.cartStore = new CartStore(this);
    this.coverageAreaStore = new CoverageAreaStore(this);
    this.orderStore = new OrderStore(this);
    this.productStore = new ProductStore(this);
    this.sellerStore = new SellerStore(this);
    this.voucherStore = new VoucherStore(this);
  }
}

export default RootStore;
