export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const defaultCurrencyOpts = {
  symbol: "Rp",
  separator: ".",
  precision: 0,
};
export const defaultNumericOpts = { symbol: "", separator: ".", precision: 0 };

export function normalizePhoneWithZero(phoneNumber: string) {
  if (phoneNumber?.startsWith("8")) {
    return `0${phoneNumber}`;
  }
  if (phoneNumber?.startsWith("62")) {
    return `0${phoneNumber.substring(2)}`;
  }
  return phoneNumber;
}

export function normalizePhoneWithoutZero(phoneNumber: string) {
  if (phoneNumber?.startsWith("08")) {
    return phoneNumber?.substring(1);
  }
  if (phoneNumber?.startsWith("62")) {
    return phoneNumber?.substring(2);
  }
  return phoneNumber;
}

export function normalizePhoneWithCountryCode(phoneNumber: string) {
  if (phoneNumber?.startsWith("8")) {
    return `62${phoneNumber}`;
  }
  if (phoneNumber?.startsWith("08")) {
    return `62${phoneNumber?.substring(1)}`;
  }
  return phoneNumber;
}
