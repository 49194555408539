import { Instance, types } from "mobx-state-tree";
import { AdminFeeType } from "../../utils/enum";
import { BuyerAdminFeeResponse } from "../../utils/interfaces";
import {
  Product,
  ProductModel,
  ProductUnit,
  ProductUnitModel,
} from "../product-store/product-store.model";
import { SalesModel } from "../seller-store/seller-store.model";
import { VoucherModel } from "../voucher-store/voucher-store.model";

/**
 * CART ITEM MODEL
 */

export const CartItemModel = types.model("CartItemModel").props({
  cartId: types.string,
  product: types.maybe(ProductModel),
  selectedUnit: types.maybe(ProductUnitModel),
  qty: types.number,
  isProductAvailable: types.boolean,
  isUnitAvailable: types.boolean,
  isPriceChanged: types.boolean,
  previousSelectedUnit: types.maybe(ProductUnitModel),
  amountChanged: types.maybeNull(types.number),
  selected: types.boolean,
});

export type CartItem = Instance<typeof CartItemModel>;

export function mapToCartItem(
  product: Product,
  unit: ProductUnit,
  qty: number
): CartItem {
  return {
    cartId: `C-${Math.floor(Math.random() * 1000000)}`,
    product: product,
    selectedUnit: unit,
    qty,
    isProductAvailable: true,
    isUnitAvailable: true,
    isPriceChanged: false,
    previousSelectedUnit: null,
    amountChanged: null,
    selected: true,
  };
}

export const BuyerAdminFeeModel = types.model("BuyerAdminFeeModel").props({
  id: types.string,
  type: types.enumeration<AdminFeeType>(
    "AdminFeeType",
    Object.values(AdminFeeType)
  ),
  percentageAmount: types.number,
  dueDays: types.number,
  amount: types.number,
  minimumPaidCashTimes: types.number,
  minimumPaidTempoTimes: types.number,
  completedPaidCashTimes: types.number,
  completedPaidTempoTimes: types.number,
  completeBuyerVerification: types.boolean,
  active: types.boolean,
  tempoActive: types.boolean,
});

export type BuyerAdminFee = Instance<typeof BuyerAdminFeeModel>;

export function mapBuyerPaymentMethodsResponseToBuyerAdminFee(
  response: BuyerAdminFeeResponse
): BuyerAdminFee {
  return {
    id: response.id,
    type:
      response.type === AdminFeeType.cash
        ? AdminFeeType.cash
        : AdminFeeType.tempo,
    percentageAmount: response.percentage_amount,
    dueDays: response.due_days,
    amount: response.amount,
    minimumPaidCashTimes: response.minimumPaidCashTimes,
    minimumPaidTempoTimes: response.minimumPaidTempoTimes,
    completedPaidCashTimes: response.completedPaidCashTimes,
    completedPaidTempoTimes: response.completedPaidTempoTimes,
    completeBuyerVerification: response.completeBuyerVerification,
    active: response.active,
    tempoActive: response.tempoActive,
  };
}

export const CustomerAddressDataModel = types
  .model("CustomerAddressDataModel")
  .props({
    address: types.string,
    latitude: types.maybe(types.number),
    longitude: types.maybe(types.number),
    stateId: types.maybe(types.number),
    cityId: types.maybe(types.number),
    districtId: types.maybe(types.number),
    subdistrictId: types.maybe(types.number),
    zipcode: types.maybe(types.string),
    notes: types.maybe(types.string), // address notes
    placeId: types.maybeNull(types.string),
  });

export type CustomerAddressData = Instance<typeof CustomerAddressDataModel>;

export const CheckoutDataModel = types.model("CheckoutDataModel").props({
  customerName: types.string,
  customerPhone: types.string,
  recipientPhone: types.string,
  deliveryType: types.string,
  customerAddress: types.maybe(CustomerAddressDataModel),
  orderNotes: types.maybe(types.string),
  sales: types.maybe(SalesModel),
  paymentMethod: types.maybe(BuyerAdminFeeModel),
  voucher: types.maybeNull(VoucherModel),
});

export type CheckoutData = Instance<typeof CheckoutDataModel>;
