import React, { ReactNode } from "react";
import { enableStaticRendering } from "mobx-react";
import makeInspectable from "mobx-devtools-mst";
import RootStore from "./root-store";

const isServer = typeof window === "undefined";
enableStaticRendering(isServer);

let store: RootStore;

export default function initializeStore(): RootStore {
  const _store = store ?? new RootStore();
  // for SSG and SSR always create a new store
  if (isServer) return _store;
  // create the store once in the client
  if (!store) store = _store;

  if (process && process.env.NODE_ENV === "development") {
    makeInspectable(_store);
  }
  return _store;
}

const StoreContext = React.createContext<RootStore | undefined>(undefined);
StoreContext.displayName = "StoreContext";

interface StoreProviderProps {
  children: ReactNode;
}

export function StoreProvider({ children }: StoreProviderProps) {
  const store = initializeStore();
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}

export function useStores() {
  const context = React.useContext(StoreContext);
  if (context === undefined) {
    throw new Error("useStore must be used within StoreProvider");
  }
  return context;
}
