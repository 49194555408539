import { cast, Instance, types } from "mobx-state-tree";
import {
  ProductCategoryResponse,
  SellerProductCategoryResponse,
  ProductUnitResponse,
  ProductImageResponse,
  ProductResponse,
} from "../../utils/interfaces";
import { compareProductUnitByPrice } from "../../utils/product";
import {
  CoverageAreaModel,
  mapCoverageAreaObjectToCoverageArea,
} from "../coverage-area-store/coverage-area-store.model";

/**
 * PRODUCT CATEGORY MODEL
 *
 * - ProductCategoryInterface
 * - ProductCategoryModel
 * - ProductCategory
 *
 * Mappers:
 * - mapProductCategoryResponseToProductCategory
 * - mapSellerProductCategoryResponseToProductCategory
 */

interface ProductCategoryInterface {
  id: string;
  sellerId: string;
  name: string;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
}

export const ProductCategoryModel = types.model("ProductCategory").props({
  id: types.identifier,
  sellerId: types.maybeNull(types.string),
  name: types.string,
  description: types.maybeNull(types.string),
  icon: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  totalProduct: types.maybe(types.number),
  active: types.maybe(types.boolean),
  selected: types.maybe(types.boolean),
});

export type ProductCategory = Instance<typeof ProductCategoryModel>;

export function mapProductCategoryResponseToProductCategory(
  response: ProductCategoryResponse
): ProductCategoryInterface {
  return {
    id: response.id,
    sellerId: response.seller_id ?? null,
    name: response.name,
    deletedAt: response.deleted_at ?? null,
    createdAt: response.created_at ?? null,
    updatedAt: response.updated_at ?? null,
  };
}

export function mapSellerProductCategoryResponseToProductCategory(
  response: SellerProductCategoryResponse
): ProductCategory {
  return {
    id: response.id,
    sellerId: null,
    name: response.name,
    description: response.description ?? null,
    icon: response.icon ?? null,
    deletedAt: null,
    createdAt: null,
    updatedAt: null,
    totalProduct: response.total_product,
    active: response.active ?? true,
    selected: false,
  };
}

/**
 * PRODUCT UNITS MODEL
 *
 * - ProductUnitInterface
 * - ProductUnitModel
 * - ProductUnit
 *
 * Mappers:
 * - mapProductUnitResponseToProductUnit
 */

interface ProductUnitInterface {
  id: string;
  price: number;
  discountPrice: number;
  productId: string;
  unit: string;
  ratio: number;
  active: boolean;
  availableOnline: boolean;
  minQty: number;
  wholesalePrice: boolean;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
  featured: boolean;
}

export const ProductUnitModel = types.model("ProductUnit").props({
  id: types.maybeNull(types.identifier),
  key: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  basePrice: types.maybeNull(types.number),
  discountPrice: types.maybeNull(types.number),
  productId: types.maybeNull(types.string),
  unit: types.maybeNull(types.string),
  ratio: types.maybeNull(types.number),
  active: types.maybeNull(types.boolean),
  availableOnline: types.maybeNull(types.boolean),
  minQty: types.maybeNull(types.number),
  userUnitId: types.maybeNull(types.string),
  wholesalePrice: types.maybeNull(types.boolean),
  deletedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  featured: types.maybeNull(types.boolean),
});

export type ProductUnit = Instance<typeof ProductUnitModel>;

export function mapProductUnitsResponseToProductUnits(
  response: ProductUnitResponse[]
): ProductUnitInterface[] {
  if (!response) {
    return [];
  }

  return response
    ?.map((unit) => ({
      id: unit.id ?? null,
      key: unit.id,
      price: unit.price,
      basePrice: unit.base_price ?? 0,
      discountPrice: unit.discount_price,
      productId: unit.product_id ?? null,
      unit: unit.unit,
      ratio: unit.ratio,
      active: unit.active,
      availableOnline: unit.available_online ?? null,
      minQty: unit.min_quantity ?? 0,
      userUnitId: unit.user_unit_id ?? null,
      wholesalePrice: unit.wholesale_price,
      deletedAt: unit.deleted_at ?? null,
      createdAt: unit.created_at ?? null,
      updatedAt: unit.updated_at ?? null,
      featured: !!unit.featured,
    }))
    .sort(compareProductUnitByPrice);
}

/**
 * PRODUCT IMAGE MODEL
 *
 * - ProductImageInterface
 * - ProductImageModel
 * - ProductImage
 *
 * Mappers:
 * - mapProductResponseToProduct
 */

export interface ProductImageInterface {
  id: string;
  productId: string;
  imageUrl: string;
  active: boolean;
  featured: boolean;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
}

export const ProductImageModel = types.model("ProductImage").props({
  id: types.identifier,
  productId: types.string,
  imageUrl: types.maybeNull(types.string),
  active: types.boolean,
  featured: types.boolean,
  deletedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});

export type ProductImage = Instance<typeof ProductImageModel>;

export function mapProductImagesResponseToProductImages(
  response: ProductImageResponse[]
): ProductImageInterface[] {
  return response?.map((result) => ({
    id: result.id,
    productId: result.product_id,
    imageUrl: result.image_url,
    active: result.active,
    featured: result.featured,
    deletedAt: result.deleted_at ?? null,
    createdAt: result.created_at ?? null,
    updatedAt: result.updated_at ?? null,
  }));
}

/**
 * PRODUCT MODEL
 *
 * - ProductModel
 * - Product
 * - ProductMap
 *
 * Mappers:
 * - mapProductResultToProduct
 */

export const ProductModel = types.model("Product").props({
  id: types.identifier,
  sellerId: types.string,
  productCategoryId: types.string,
  name: types.string,
  sku: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  mainPictureUrl: types.maybeNull(types.string),
  available: types.boolean,
  promoActive: types.boolean,
  deletedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  productImages: types.optional(types.array(ProductImageModel), []),
  productCategory: types.maybeNull(ProductCategoryModel),
  productUnits: types.optional(types.array(ProductUnitModel), []),
  baseUnits: types.optional(types.array(ProductUnitModel), []),
  wholesaleUnits: types.optional(types.array(ProductUnitModel), []),
  selected: types.boolean,
  minPrice: types.number,
  productBought: types.maybeNull(types.number),
  ranked: types.number,
  referenceProductId: types.string,
  coverageAreaId: types.string,
  coverageArea: types.maybeNull(CoverageAreaModel),
  productCoverageAreas: types.optional(types.array(CoverageAreaModel), []),
  // CoverageArea: TODO: map coverageArea
});

export type Product = Instance<typeof ProductModel>;

export type ProductMap = Map<string, Product>;

function sortByPrice(a: ProductUnit, b: ProductUnit) {
  if (a.price > b.price) return 1;
  if (b.price > a.price) return -1;
  return 0;
}

function sortByMinQty(a: ProductUnit, b: ProductUnit) {
  if (a.minQty < b.minQty) return 1;
  if (b.minQty < a.minQty) return -1;
  return 0;
}

export function mapProductResponseToProduct(
  response: ProductResponse
): Product {
  let productUnits: ProductUnitInterface[] = [];
  if (response?.ParentProduct) {
    productUnits = response.ParentProduct?.ProductUnits
      ? mapProductUnitsResponseToProductUnits(
        response.ParentProduct.ProductUnits
      )
      : [];
  } else {
    productUnits =
      response.ProductUnits || response.units
        ? mapProductUnitsResponseToProductUnits(
          response.ProductUnits || response.units
        )
        : [];
  }

  productUnits.sort(sortByPrice).sort(sortByMinQty);

  const baseUnits = productUnits.filter((unit) => !unit.wholesalePrice);
  const wholesaleUnits = productUnits.filter((unit) => unit.wholesalePrice);
  return {
    id: response.id,
    sellerId: response.seller_id ?? null,
    productCategoryId: response.product_category_id ?? null,
    name: response.name,
    sku: response.sku ?? null,
    description: response.description ?? null,
    mainPictureUrl: response.main_picture_url ?? null,
    available: !!response.available,
    promoActive: response.promo_active
      ? response.promo_active
      : productUnits?.some((u) => u.discountPrice > 0),
    deletedAt: response.deleted_at ?? null,
    createdAt: response.created_at ?? null,
    updatedAt: response.updated_at ?? null,
    productImages: cast(
      response.ProductImages
        ? mapProductImagesResponseToProductImages(response.ProductImages)
        : []
    ),
    productCategory: cast(
      response.ProductCategory
        ? mapProductCategoryResponseToProductCategory(response.ProductCategory)
        : null
    ),
    productUnits: cast(productUnits), // all units
    baseUnits: cast(baseUnits), // units non-grosir
    wholesaleUnits: cast(wholesaleUnits), // units grosir
    selected: false,
    minPrice: response.min_price ?? null,
    productBought: response.product_bought ?? null,
    ranked: response.ranked ?? null,
    referenceProductId: response.reference_product_id,
    coverageAreaId: response.coverage_area_id ?? null,
    coverageArea: response?.CoverageArea
      ? mapCoverageAreaObjectToCoverageArea(response.CoverageArea)
      : null,
    productCoverageAreas: cast(
      response?.ProductCoverageAreas?.length
        ? response.ProductCoverageAreas.map((pca) =>
          mapCoverageAreaObjectToCoverageArea(pca.CoverageArea)
        )
        : []
    ),
  };
}
