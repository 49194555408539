import { types, Instance, cast } from "mobx-state-tree";
import {
  VoucherSellerObject,
  VoucherAreaObject,
  VoucherCategoryObject,
  VoucherObject,
} from "../../utils/interfaces";

/**
 * VOUCHER
 */

export const VoucherSellerModel = types.model("VoucherSeller").props({
  id: types.maybe(types.string),
  sellerId: types.maybe(types.string),
  sellerName: types.maybe(types.string),
  sellerUsername: types.maybe(types.string),
});

export type VoucherSeller = Instance<typeof VoucherSellerModel>;

export function mapVoucherSellerObjectToVoucherSeller(
  voucherSellersObj: VoucherSellerObject[]
): VoucherSeller[] {
  return voucherSellersObj.map((obj) => ({
    id: obj?.id,
    sellerId: obj.SellerDetail?.id,
    sellerName: obj.SellerDetail?.User?.name,
    sellerUsername: obj.SellerDetail?.User?.username,
  }));
}

export const VoucherAreaModel = types.model("VoucherArea").props({
  id: types.maybe(types.string),
  areaId: types.maybe(types.string),
  areaName: types.maybe(types.string),
});

export type VoucherArea = Instance<typeof VoucherAreaModel>;

export function mapVoucherAreaObjectToVoucherArea(
  voucherAreasObj: VoucherAreaObject[]
): VoucherArea[] {
  return voucherAreasObj.map((obj) => ({
    id: obj?.id,
    areaId: obj?.coverage_area_id,
    areaName: obj?.CoverageArea?.name,
  }));
}

export const VoucherCategoryModel = types.model("VoucherCategory").props({
  id: types.maybe(types.string),
  productCategoryId: types.maybe(types.string),
  productCategoryName: types.maybe(types.string),
  sellerName: types.maybe(types.string),
});

export type VoucherCategory = Instance<typeof VoucherCategoryModel>;

export function mapVoucherCategoryObjectToVoucherCategory(
  voucherCategoriesObj: VoucherCategoryObject[]
): VoucherCategory[] {
  return voucherCategoriesObj.map((obj) => ({
    id: obj?.id,
    productCategoryId: obj?.ProductCategory?.id,
    productCategoryName: obj?.ProductCategory?.name,
    sellerName: obj?.ProductCategory?.SellerDetail?.User?.name,
  }));
}

export const AppliedVoucherModel = types.model("AppliedVoucher").props({
  discountAmount: types.maybeNull(types.number),
  discountDeliveryFee: types.maybeNull(types.number),
});

export type AppliedVoucher = Instance<typeof AppliedVoucherModel>;

export const VoucherModel = types.model("Voucher").props({
  id: types.maybe(types.string),
  voucherType: types.maybe(types.string),
  voucherCode: types.maybe(types.string),
  promoName: types.maybe(types.string),
  minTotalPurchase: types.maybe(types.number),
  discountAmount: types.maybe(types.number),
  discountPercentageAmount: types.maybeNull(types.number),
  discountDeliveryFee: types.maybeNull(types.number),
  discountPercentageDeliveryFee: types.maybeNull(types.number),
  maxNominalDiscount: types.maybeNull(types.number),
  startAt: types.maybe(types.string),
  endAt: types.maybe(types.string),
  limitUser: types.maybe(types.number),
  voucherQuota: types.maybe(types.number),
  voucherUsed: types.maybeNull(types.number),
  userType: types.maybeNull(types.string),
  paymentMethod: types.maybe(types.string),
  status: types.maybe(types.string),
  voucherDescription: types.maybe(types.string),
  createdBy: types.maybe(types.string),
  stopAt: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  createdAt: types.maybe(types.string),
  updatedAt: types.maybe(types.string),
  voucherSellers: types.maybe(types.array(VoucherSellerModel)),
  voucherAreas: types.maybe(types.array(VoucherAreaModel)),
  voucherCategories: types.maybe(types.array(VoucherCategoryModel)),
  appliedVoucher: types.maybeNull(AppliedVoucherModel),
  hasCategory: types.maybeNull(types.boolean),
});

export type Voucher = Instance<typeof VoucherModel>;

export function mapVoucherObjectToVoucher(obj: VoucherObject): Voucher {
  return {
    id: obj?.id,
    voucherType: obj?.voucher_type,
    voucherCode: obj?.voucher_code,
    promoName: obj?.promo_name,
    minTotalPurchase: obj?.min_total_purchase ?? 0,
    discountAmount: obj?.discount_amount ?? 0,
    discountPercentageAmount: obj?.discount_percentage_amount ?? 0,
    discountDeliveryFee: obj?.discount_delivery_fee ?? 0,
    discountPercentageDeliveryFee: obj?.discount_percentage_delivery_fee ?? 0,
    maxNominalDiscount: obj?.max_nominal_discount ?? 0,
    startAt: obj?.start_at?.split(".")[0], // from 2022-05-18T00:00:00.000Z to 2022-05-18T00:00:00
    endAt: obj?.end_at?.split(".")[0], // from 2022-05-31T23:59:59.999Z to 2022-05-31T23:59:59
    limitUser: obj?.limit_user ?? 0,
    voucherQuota: obj?.voucher_quota,
    voucherUsed: obj?.voucher_used ?? 0,
    userType: obj?.user_type,
    paymentMethod: obj?.payment_method,
    status: obj?.status,
    voucherDescription: obj?.voucher_description,
    createdBy: obj?.created_by,
    stopAt: obj?.stop_at,
    deletedAt: obj?.deleted_at,
    createdAt: obj?.created_at,
    updatedAt: obj?.updated_at,
    voucherSellers: cast(
      obj?.VoucherSellers
        ? mapVoucherSellerObjectToVoucherSeller(obj.VoucherSellers)
        : []
    ),
    voucherAreas: cast(
      obj?.VoucherAreas
        ? mapVoucherAreaObjectToVoucherArea(obj.VoucherAreas)
        : []
    ),
    voucherCategories: cast(
      obj?.VoucherCategories
        ? mapVoucherCategoryObjectToVoucherCategory(obj.VoucherCategories)
        : []
    ),
    appliedVoucher: obj.AppliedVoucher
      ? {
          discountAmount: obj.AppliedVoucher.discount_amount,
          discountDeliveryFee: obj.AppliedVoucher.discount_delivery_fee,
        }
      : null,
    hasCategory: obj.has_category ?? null,
  };
}
