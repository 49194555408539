import Cookie from "js-cookie";
import { milisecondsToDays } from "./date";

export const ACCESS_TOKEN = "AT_CRSMB";
export const REFRESH_TOKEN = "RT_CRSMB";

export const getCookies = () => Cookie.get();

export const setAccessTokenCookie = (accessToken: {
  token: string;
  expired_at: number;
}) => {
  Cookie.set(ACCESS_TOKEN, accessToken.token, {
    expires: milisecondsToDays(accessToken.expired_at, false),
  });
};

export const getAccessTokenCookie = (): string => {
  try {
    const accessTokenCookie = Cookie.get(ACCESS_TOKEN);
    return accessTokenCookie ? accessTokenCookie : null;
  } catch (e) {
    return null;
  }
};

export const getRefreshTokenCookie = (): string => {
  const refreshTokenCookie = Cookie.get(REFRESH_TOKEN);
  return refreshTokenCookie ? refreshTokenCookie : null;
};

export const isAuthenticated = () => !!getAccessTokenCookie();

export const clearAccessTokenCookie = () => {
  Cookie.remove(ACCESS_TOKEN);
  Cookie.remove(REFRESH_TOKEN);
};

export const getCookie = (cookieId: string) => {
  return Cookie.get(cookieId);
};

export const setCookie = (
  cookieId: string,
  cookie: any,
  expiredAt?: number
) => {
  Cookie.set(cookieId, cookie, {
    expires: expiredAt ? milisecondsToDays(expiredAt) : 2,
  });
};

export const removeCookie = (cookieId: string) => {
  Cookie.remove(cookieId);
};
