import React, { useEffect, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { Analytics } from '@vercel/analytics/react';
import Bugsnag from "../services/bugsnag";
import { StoreProvider } from "../stores/stores";
import { Snackbar, SnackbarProvider } from "../components/alerts/snackbar";
import { LoadingOverlay } from "../components/loading";
import "../theme/main.css";
import { ErrorFallback } from "../components/error/error-fallback";

declare global {
  interface Window {
    webEventHandler: any;
  }
}

const ErrorBoundary = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
  ? Bugsnag.getPlugin("react").createErrorBoundary()
  : React.Fragment;

function Credix({ Component, pageProps }) {
  const [isChangingRoute, setIsChangingRoute] = useState<boolean>(false);
  const router = useRouter();

  const handleSetPrevPathname = () => {
    if (window) {
      window.__prevPathname = window.location.pathname;
    }
  };

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      setIsChangingRoute(false);
      if (process.env.NEXT_PUBLIC_G_TRACKING_ID) {
        window.gtag("config", process.env.NEXT_PUBLIC_G_TRACKING_ID, {
          page_path: url,
        });
      }
    };

    router.events.on("beforeHistoryChange", handleSetPrevPathname);
    router.events.on("routeChangeStart", () => setIsChangingRoute(true));
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("routeChangeError", () => setIsChangingRoute(false));

    return () => {
      router.events.off("beforeHistoryChange", handleSetPrevPathname);
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBuyerAppPage =
    router.pathname.startsWith("/[seller]") ||
    router.pathname.startsWith("/pesan");

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap" rel="stylesheet" />
      </Head>
      {process.env.NEXT_PUBLIC_G_TRACKING_ID && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_G_TRACKING_ID}`}
          />
          <Script
            id="gtag"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('config', '${process.env.NEXT_PUBLIC_G_TRACKING_ID}', {
								page_path: window.location.pathname,
							});`,
            }}
          />
        </>
      )}
      {process.env.NEXT_PUBLIC_GTM_ID && (
        <Script
          id="gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
							new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
							j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
							'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
							})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`,
          }}
        />
      )}
      <ErrorBoundary
        {...(React.isValidElement(ErrorBoundary) && {
          FallbackComponent: ErrorFallback,
        })}
      >
        <StoreProvider>
          <SnackbarProvider>
            <div id="apps">
              <Snackbar />
              {isBuyerAppPage && <LoadingOverlay visible={isChangingRoute} />}
              <Component {...pageProps} />
            </div>
          </SnackbarProvider>
        </StoreProvider>
      </ErrorBoundary>
      <Analytics />
    </>
  );
}

export default Credix;
