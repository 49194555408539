export const MSG_GLOBAL_ERROR = "Terjadi kesalahan, silakan ulangi kembali";
export const MSG_GLOBAL_SUCCESS = "Request berhasil";
export const MSG_GLOBAL_RESTRICTED_ACCESS = "Anda tidak memiliki akses";

// ORDER STATUS
export const ORDER_STATUS_CREATED = "created";
export const ORDER_STATUS_ACCEPTED = "accepted";
export const ORDER_STATUS_SHIPPED = "shipped";
export const ORDER_STATUS_READY_TO_PICKUP = "ready_pickup"; // frontend label if order (status === shipped && delivery_type === pickup)
export const ORDER_STATUS_ON_DELIVERY = "on_delivery";
export const ORDER_STATUS_COMPLETED = "completed";
export const ORDER_STATUS_CANCELLED = "cancelled";

// ORDER DELIVERY TYPE
export const ORDER_DELIVERY_TYPE_DELIVERY = "delivery";
export const ORDER_DELIVERY_TYPE_PICKUP = "pickup";
export const ORDER_DELIVERY_TYPE_DELIVERY_PICKUP = "delivery_pickup";

// PAYMENT STATUS
export const PAYMENT_STATUS_PAID = "paid";
export const PAYMENT_STATUS_UNPAID = "unpaid";

export const DEFAULT_ITEM_PER_PAGE = 50;
export const DEFAULT_IMAGE_PER_PRODUCT = 6;

// DEFAULT ROLE IDS
export const SUPERADMIN_ID = 1;
export const ADMIN_ID = 2;
export const SALES_ID = 3;
export const DELIVERYMAN_ID = 4;

// DEFAULT STAFF PERMISSION IDS
export const PERMISSION_WEB_TAMBAH_UBAH_PRODUK = 3;
export const PERMISSION_WEB_SET_STOK_PRODUK_HABIS = 4;
export const PERMISSION_WEB_TOLAK_DAN_BATALKAN_PESANAN = 5;
export const PERMISSION_WEB_TERIMA_PESANAN = 6;
export const PERMISSION_WEB_UBAH_HAPUS_PRODUK_DI_PESANAN = 7;
export const PERMISSION_WEB_TENTUKAN_BIAYA_ADMIN = 8;
export const PERMISSION_WEB_SET_TRANSAKSI_JADI_LUNAS = 9;
export const PERMISSION_WEB_BAGIKAN_INVOICE_PESANAN = 10;
export const PERMISSION_WEB_BAGIKAN_KATALOG_PRODUK = 11;
export const PERMISSION_WEB_KONFIRMASI_PESANAN = 12;
export const PERMISSION_WEB_ATUR_JATUH_TEMPO = 13;
export const PERMISSION_APP_SET_PESANAN_JADI_SELESAI_DIANTAR = 14;
export const PERMISSION_APP_HUBUNGI_BUYER_VIA_WHATSAPP = 15;
export const PERMISSION_APP_UBAH_PESANAN = 16;
export const PERMISSION_APP_SET_TRANSAKSI_JADI_LUNAS = 17;
export const PERMISSION_WEB_UBAH_PROFIL = 18;
export const PERMISSION_WEB_PENGATURAN_PEMBAYARAN = 19;
export const PERMISSION_WEB_PENGATURAN_PENGIRIMAN = 20;
export const PERMISSION_WEB_SET_PESANAN_JADI_SELESAI = 21;
export const PERMISSION_WEB_HAPUS_PRODUK = 22;
export const PERMISSION_APP_TOLAK_DAN_BATALAKAN_PESANAN = 23;
export const PERMISSION_APP_TERIMA_PESANAN = 24;
export const PERMISSION_APP_KONFIRMASI_PESANAN = 25;
export const PERMISSION_APP_BAGIKAN_INVOICE_PESANAN = 26;
export const PERMISSION_APP_SELESAIKAN_PESANAN_DIAMBIL_SENDIRI = 27;
export const PERMISSION_APP_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY = 28;
export const PERMISSION_WEB_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY_DARI_WEB = 29;
export const PERMISSION_APP_TAMBAH_DAN_UBAH_PRODUK_DARI_MOBILE = 30;
export const PERMISSION_APP_UBAH_PRODUK_DARI_MOBILE = 31;
export const PERMISSION_APP_HAPUS_PRODUK_DARI_MOBILE = 32;
export const PERMISSION_WEB_UBAH_PESANAN_DAN_UBAH_SALES_DARI_WEB = 33;
export const PERMISSION_APP_UBAH_PESANAN_DAN_UBAH_SALES_DARI_MOBILE = 34;

export const PRODUCT_PERMISSION_IDS = [
  PERMISSION_WEB_TAMBAH_UBAH_PRODUK,
  PERMISSION_WEB_SET_STOK_PRODUK_HABIS,
  PERMISSION_WEB_HAPUS_PRODUK,
  PERMISSION_APP_TAMBAH_DAN_UBAH_PRODUK_DARI_MOBILE,
  PERMISSION_APP_UBAH_PRODUK_DARI_MOBILE,
  PERMISSION_APP_HAPUS_PRODUK_DARI_MOBILE,
];
export const ORDER_PERMISSION_IDS = [
  PERMISSION_WEB_TOLAK_DAN_BATALKAN_PESANAN,
  PERMISSION_WEB_TERIMA_PESANAN,
  PERMISSION_WEB_UBAH_HAPUS_PRODUK_DI_PESANAN,
  PERMISSION_WEB_TENTUKAN_BIAYA_ADMIN,
  PERMISSION_WEB_SET_TRANSAKSI_JADI_LUNAS,
  PERMISSION_WEB_BAGIKAN_INVOICE_PESANAN,
  PERMISSION_WEB_BAGIKAN_KATALOG_PRODUK,
  PERMISSION_WEB_KONFIRMASI_PESANAN,
  PERMISSION_WEB_ATUR_JATUH_TEMPO,
  PERMISSION_WEB_SET_PESANAN_JADI_SELESAI,
  PERMISSION_WEB_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY_DARI_WEB,
  PERMISSION_WEB_UBAH_PESANAN_DAN_UBAH_SALES_DARI_WEB,
  PERMISSION_APP_UBAH_PESANAN_DAN_UBAH_SALES_DARI_MOBILE,
];
export const SALES_PERMISSION_IDS = [
  PERMISSION_APP_UBAH_PESANAN,
  PERMISSION_APP_SET_TRANSAKSI_JADI_LUNAS,
  PERMISSION_APP_TOLAK_DAN_BATALAKAN_PESANAN,
  PERMISSION_APP_TERIMA_PESANAN,
  PERMISSION_APP_KONFIRMASI_PESANAN,
  PERMISSION_APP_BAGIKAN_INVOICE_PESANAN,
  PERMISSION_APP_SELESAIKAN_PESANAN_DIAMBIL_SENDIRI,
];
export const DELIVERY_PERMISSION_IDS = [
  PERMISSION_APP_SET_PESANAN_JADI_SELESAI_DIANTAR,
  PERMISSION_APP_HUBUNGI_BUYER_VIA_WHATSAPP,
  PERMISSION_APP_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY,
];
export const ACCOUNT_PERMISSION_IDS = [
  PERMISSION_WEB_UBAH_PROFIL,
  PERMISSION_WEB_PENGATURAN_PEMBAYARAN,
  PERMISSION_WEB_PENGATURAN_PENGIRIMAN,
];
export const PERMISSION_DESCRIPTION = {
  [PERMISSION_WEB_TAMBAH_UBAH_PRODUK]: "Tambah & Ubah Produk",
  [PERMISSION_WEB_SET_STOK_PRODUK_HABIS]: "Set Stok Produk Habis",
  [PERMISSION_WEB_TOLAK_DAN_BATALKAN_PESANAN]: "Tolak & Batalkan Pesanan",
  [PERMISSION_WEB_TERIMA_PESANAN]: "Terima Pesanan",
  [PERMISSION_WEB_UBAH_HAPUS_PRODUK_DI_PESANAN]:
    "Ubah & Hapus Produk di Pesanan",
  [PERMISSION_WEB_TENTUKAN_BIAYA_ADMIN]: "Tentukan Biaya Admin",
  [PERMISSION_WEB_SET_TRANSAKSI_JADI_LUNAS]: "Set Transaksi jadi Lunas",
  [PERMISSION_WEB_BAGIKAN_INVOICE_PESANAN]: "Bagikan Invoice Pesanan",
  [PERMISSION_WEB_BAGIKAN_KATALOG_PRODUK]: "Bagikan Katalog Produk",
  [PERMISSION_WEB_KONFIRMASI_PESANAN]: "Konfirmasi Pesanan",
  [PERMISSION_WEB_ATUR_JATUH_TEMPO]: "Atur Jatuh Tempo",
  [PERMISSION_APP_SET_PESANAN_JADI_SELESAI_DIANTAR]:
    "Set Pesanan jadi Selesai Diantar",
  [PERMISSION_APP_HUBUNGI_BUYER_VIA_WHATSAPP]: "Hubungi Buyer Via Whatsapp",
  [PERMISSION_APP_UBAH_PESANAN]: "Ubah Pesanan",
  [PERMISSION_APP_SET_TRANSAKSI_JADI_LUNAS]: "Set Transaksi jadi Lunas",
  [PERMISSION_WEB_UBAH_PROFIL]: "Ubah Profil",
  [PERMISSION_WEB_PENGATURAN_PEMBAYARAN]: "Pengaturan Pembayaran",
  [PERMISSION_WEB_PENGATURAN_PENGIRIMAN]: "Pengaturan Pengiriman",
  [PERMISSION_WEB_SET_PESANAN_JADI_SELESAI]: "Set Pesanan Jadi Selesai",
  [PERMISSION_WEB_HAPUS_PRODUK]: "Hapus Produk",
  [PERMISSION_WEB_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY_DARI_WEB]:
    "Akses mengubah status pesanan menjadi on_delivery dari web",
  [PERMISSION_APP_TOLAK_DAN_BATALAKAN_PESANAN]: "Tolak & Batalkan Pesanan",
  [PERMISSION_APP_TERIMA_PESANAN]: "Terima Pesanan",
  [PERMISSION_APP_KONFIRMASI_PESANAN]: "Konfirmasi Pesanan",
  [PERMISSION_APP_BAGIKAN_INVOICE_PESANAN]: "Bagikan Invoice Pesanan",
  [PERMISSION_APP_SELESAIKAN_PESANAN_DIAMBIL_SENDIRI]:
    "Selesai-kan Pesanan Diambil Sendiri",
  [PERMISSION_APP_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY]:
    "Akses mengubah status pesanan menjadi on_delivery",
  [PERMISSION_APP_TAMBAH_DAN_UBAH_PRODUK_DARI_MOBILE]:
    "Tambah & Ubah Produk dari mobile",
  [PERMISSION_APP_UBAH_PRODUK_DARI_MOBILE]: "Ubah Produk dari mobile",
  [PERMISSION_APP_HAPUS_PRODUK_DARI_MOBILE]: "Hapus Produk dari mobile",
  [PERMISSION_WEB_UBAH_PESANAN_DAN_UBAH_SALES_DARI_WEB]:
    "Ubah Pesanan & Ubah Sales dari web",
  [PERMISSION_APP_UBAH_PESANAN_DAN_UBAH_SALES_DARI_MOBILE]:
    "Ubah Pesanan & Ubah Sales dari mobile",
};
export const PERMISSION_ACCESS_PLATFORM = {
  [PERMISSION_WEB_TAMBAH_UBAH_PRODUK]: "web",
  [PERMISSION_WEB_SET_STOK_PRODUK_HABIS]: "web",
  [PERMISSION_WEB_TOLAK_DAN_BATALKAN_PESANAN]: "web",
  [PERMISSION_WEB_TERIMA_PESANAN]: "web",
  [PERMISSION_WEB_UBAH_HAPUS_PRODUK_DI_PESANAN]: "web",
  [PERMISSION_WEB_TENTUKAN_BIAYA_ADMIN]: "web",
  [PERMISSION_WEB_SET_TRANSAKSI_JADI_LUNAS]: "web",
  [PERMISSION_WEB_BAGIKAN_INVOICE_PESANAN]: "web",
  [PERMISSION_WEB_BAGIKAN_KATALOG_PRODUK]: "web",
  [PERMISSION_WEB_KONFIRMASI_PESANAN]: "web",
  [PERMISSION_WEB_ATUR_JATUH_TEMPO]: "web",
  [PERMISSION_APP_SET_PESANAN_JADI_SELESAI_DIANTAR]: "app",
  [PERMISSION_APP_HUBUNGI_BUYER_VIA_WHATSAPP]: "app",
  [PERMISSION_APP_UBAH_PESANAN]: "app",
  [PERMISSION_APP_SET_TRANSAKSI_JADI_LUNAS]: "app",
  [PERMISSION_WEB_UBAH_PROFIL]: "web",
  [PERMISSION_WEB_PENGATURAN_PEMBAYARAN]: "web",
  [PERMISSION_WEB_PENGATURAN_PENGIRIMAN]: "web",
  [PERMISSION_WEB_SET_PESANAN_JADI_SELESAI]: "web",
  [PERMISSION_WEB_HAPUS_PRODUK]: "web",
  [PERMISSION_WEB_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY_DARI_WEB]:
    "web",
  [PERMISSION_APP_TOLAK_DAN_BATALAKAN_PESANAN]: "app",
  [PERMISSION_APP_TERIMA_PESANAN]: "app",
  [PERMISSION_APP_KONFIRMASI_PESANAN]: "app",
  [PERMISSION_APP_BAGIKAN_INVOICE_PESANAN]: "app",
  [PERMISSION_APP_SELESAIKAN_PESANAN_DIAMBIL_SENDIRI]: "app",
  [PERMISSION_APP_AKSES_MENGUBAH_STATUS_PESANAN_MENJADI_ON_DELIVERY]: "app",
  [PERMISSION_APP_TAMBAH_DAN_UBAH_PRODUK_DARI_MOBILE]: "app",
  [PERMISSION_APP_UBAH_PRODUK_DARI_MOBILE]: "app",
  [PERMISSION_APP_HAPUS_PRODUK_DARI_MOBILE]: "app",
  [PERMISSION_WEB_UBAH_PESANAN_DAN_UBAH_SALES_DARI_WEB]: "web",
  [PERMISSION_APP_UBAH_PESANAN_DAN_UBAH_SALES_DARI_MOBILE]: "app",
};

export const DEFAULT_PRODUCT_CATEGORY_ICON = "/img/category/default.svg";

export const PRODUCT_CATEGORY_ICONS = [
  { name: "Buku", url: "/img/category/buku.svg" },
  { name: "Peralatan Dapur", url: "/img/category/peralatan-dapur.svg" },
  { name: "Fashion Anak Bayi", url: "/img/category/fashion-anak-bayi.svg" },
  { name: "Fashion Muslim", url: "/img/category/fashion-muslim.svg" },
  { name: "Fashion Wanita", url: "/img/category/fashion-wanita.svg" },
  { name: "Fashion Pria", url: "/img/category/fashion-pria.svg" },
  { name: "Musik", url: "/img/category/musik.svg" },
  { name: "Gaming", url: "/img/category/gaming.svg" },
  { name: "Mainan", url: "/img/category/mainan.svg" },
  { name: "Handphone", url: "/img/category/handphone.svg" },
  { name: "Kebutuhan Bayi", url: "/img/category/kebutuhan-bayi.svg" },
  { name: "Kamera", url: "/img/category/kamera.svg" },
  { name: "Kecantikan", url: "/img/category/kecantikan.svg" },
  { name: "Rumah Tangga", url: "/img/category/rumah-tangga.svg" },
  { name: "Komputer", url: "/img/category/komputer.svg" },
  { name: "Elektronik", url: "/img/category/elektronik.svg" },
  { name: "Voucher Pulsa", url: "/img/category/voucher-pulsa.svg" },
  { name: "Makanan", url: "/img/category/makanan.svg" },
  { name: "Minuman", url: "/img/category/minuman.svg" },
  { name: "Olahraga", url: "/img/category/olahraga.svg" },
  { name: "Otomotif", url: "/img/category/otomotif.svg" },
  { name: "Perlengkapan Hewan", url: "/img/category/perlengkapan-hewan.svg" },
  { name: "Perlengkapan Pesta", url: "/img/category/perlengkapan-pesta.svg" },
  { name: "Perawatan Tubuh", url: "/img/category/perawatan-tubuh.svg" },
  { name: "Pertukangan", url: "/img/category/pertukangan.svg" },
  { name: "Bakery", url: "/img/category/bakery.svg" },
  { name: "Obat-obatan", url: "/img/category/obat-obatan.svg" },
  { name: "Stationary", url: "/img/category/stationary.svg" },
];

export const MAP_DELIVERY_TYPE = {
  delivery: "Dikirim",
  pickup: "Diambil di tempat",
};

export const MAP_PAYMENT_STATUS = {
  unpaid: {
    text: "Belum Dibayar",
    bgColor: "bg-order-cancelled",
  },
  paid: {
    text: "Lunas",
    bgColor: "bg-order-completed",
  },
  paid_sales: {
    text: "Lunas",
    bgColor: "bg-order-completed",
  },
  paid_cod: {
    text: "Lunas",
    bgColor: "bg-order-completed",
  },
  paid_transfer: {
    text: "Lunas",
    bgColor: "bg-order-completed",
  },
  partial_paid: {
    text: "Bayar Sebagian",
    bgColor: "bg-order-partial",
  },
  partial_paid_cod: {
    text: "Bayar Sebagian",
    bgColor: "bg-order-partial",
  },
  partial_paid_bca: {
    text: "Bayar Sebagian",
    bgColor: "bg-order-partial",
  },
};

export const MAP_ORDER_STATUS = {
  created: {
    text: "Menunggu Konfirmasi",
    textColor: "text-blue",
    bgColor: "bg-order-created",
  },
  accepted: {
    text: "Menunggu Diproses",
    textColor: "text-yellow",
    bgColor: "bg-order-accepted",
  },
  shipped: {
    text: "Pesanan Siap Diambil",
    textColor: "text-orange",
    bgColor: "bg-order-shipped",
  },
  on_delivery: {
    text: "Pesanan Dikirim",
    textColor: "text-orange",
    bgColor: "bg-order-on_delivery",
  },
  completed: {
    text: "Pesanan Selesai",
    textColor: "text-jungle",
    bgColor: "bg-order-completed",
  },
  cancelled: {
    text: "Pesanan Dibatalkan",
    textColor: "text-red",
    bgColor: "bg-order-cancelled",
  },
};

export const MAP_PAYMENT_METHOD = {
  cash: "Tunai",
  tempo: "Tempo",
};

export const MAP_PAYMENT_HISTORY = {
  "Uang Tunai": "Tunai",
  "Transfer BCA": "Transfer BCA",
  "OY! Virtual Account": "OY! Virtual Account",
};

export const MAX_BUY_COUNT = 9999;
export const MAX_ORDER_NOTES_LENGTH = 100;

// LOCAL STORAGE KEYS
export const SELLER_DATA_KEY = "sellerData";
export const USER_DATA_KEY = "userData"; // logged in user data
export const CART_DATA_KEY = "cart";
export const CHECKOUT_DATA_KEY = "checkoutData";
export const LAST_SUCCESS_ORDER_DATA_KEY = "lastSuccessOrderData";
export const SHOW_DISCLAIMER_DATA_KEY = "showDisclaimer";
export const NUM_VISIT_CART_PRICE_UPDATE_DATA_KEY = "numVisitCartPriceUpdate";
export const COVERAGE_AREA_DATA_KEY = "areaData";
export const PRODUCT_LAYOUT_KEY = "productLayout";
export const DOWNLOAD_APP_BANNER_CLOSED_KEY = "downloadAppBannerClosed";
export const UNAVAILABLE_PRODUCTS_KEY = "unavailableProducts";
export const TEMP_SELECTED_TEMPO_KEY = "tempSelectedTempo";
export const IS_AGREE_TEMPO_KEY = "isAgreeTempo";
export const IS_CANCEL_AGREE_TEMPO_KEY = "isCancelTempoAgreement";
