export const milisecondsToDays = (ms: number, rounded = true) => {
  const days = rounded
    ? Math.floor(ms / (24 * 60 * 60 * 1000))
    : ms / (24 * 60 * 60 * 1000);
  return days;
};

export function compareByDate(a, b) {
  if (a.createdAt > b.createdAt) return -1;
  if (b.createdAt > a.createdAt) return 1;
  return 0;
}
