import { cast, Instance, types } from "mobx-state-tree";
import {
  mapProductResponseToProduct,
  ProductModel,
} from "../product-store/product-store.model";
import {
  OrderItemResponse,
  SellerDetailResponse,
  OrderResponse,
  OrderCountResult,
  AdminFeeResponse,
  VoucherObject,
  CoverageAreaObject,
  PriceQuantityLogObj,
  OrderPaymentHistoryObj,
  ReturnItemResponse,
} from "../../utils/interfaces";
import { AdminFeeType } from "../../utils/enum";
import {
  DeliverymanModel,
  SalesModel,
  SellerAdminFee,
  SellerAdminFeeModel,
} from "../seller-store/seller-store.model";
import Order from "../../pages/[seller]/order";
import { MAP_PAYMENT_HISTORY } from "../../utils/constants";

/**
 * ORDER ITEM MODEL
 *
 * - OrderItemModel
 * - OrderItem
 *
 * Mappers:
 * - mapOrderItemResponseToOrderItem
 */

export const OrderItemModel = types.model("OrderItemModel").props({
  id: types.identifier,
  price: types.number,
  discountPrice: types.number,
  orderId: types.string,
  productId: types.string,
  qty: types.number,
  unit: types.string,
  revision: types.string,
  notes: types.maybeNull(types.string),
  ratio: types.number,
  createdAt: types.string,
  updatedAt: types.string,
  deletedAt: types.maybeNull(types.string),
  product: types.maybe(ProductModel),
  isNew: types.boolean,
  selected: types.boolean,
});

export type OrderItem = Instance<typeof OrderItemModel>;

export function mapOrderItemsResponseToOrderItems(
  response: OrderItemResponse[]
): OrderItem[] {
  return response.map((orderItem) => {
    return {
      id: orderItem.id,
      price: orderItem.price,
      discountPrice: orderItem.discount_price ?? null,
      orderId: orderItem.order_id,
      productId: orderItem.product_id ?? null,
      qty: orderItem.qty,
      unit: orderItem.unit,
      revision: orderItem.revision ?? null,
      notes: orderItem.notes ?? null,
      ratio: orderItem.ratio ?? null,
      createdAt: orderItem.created_at ?? null,
      updatedAt: orderItem.updated_at ?? null,
      deletedAt: orderItem.deleted_at ?? null,
      product: orderItem.Product
        ? mapProductResponseToProduct(orderItem.Product)
        : null,
      isNew: orderItem.is_new ?? false,
      selected: false,
    };
  });
}

export const OrderVoucherModel = types.model("OrderVoucher").props({
  discountPercentageAmount: types.number,
  discountPercentageDeliveryFee: types.number,
});

export type OrderVoucher = Instance<typeof OrderVoucherModel>;

export function mapOrderVoucherResponseToOrderVoucher(
  response: VoucherObject
): OrderVoucher {
  return {
    discountPercentageAmount: response.discount_percentage_amount,
    discountPercentageDeliveryFee: response.discount_percentage_delivery_fee,
  };
}

export const OrderCoverageAreaModel = types.model("OrderCoverageArea").props({
  id: types.string,
  name: types.string,
  address: types.string,
  phone: types.string,
});

export type OrderCoverageArea = Instance<typeof OrderCoverageAreaModel>;

export function mapOrderCoverageAreaResponseToOrderCoverageArea(
  response: CoverageAreaObject
): OrderCoverageArea {
  return {
    id: response.id ?? "CRA",
    name: response.name ?? "",
    address: response.address ?? "",
    phone: response.phone ?? "",
  };
}

/**
 * ORDER MODEL
 *
 * - OrderModel
 * - Order
 * - OrderMap
 *
 * Mappers:
 * - mapOrderResponseToOrder
 */

export const SellerUserModel = types.model("SellerUser").props({
  username: types.string,
  name: types.string,
  address: types.string,
  phone: types.string,
});

export type SellerUser = Instance<typeof SellerUserModel>;

export const SellerDetailModel = types.model("SellerDetail").props({
  userId: types.identifier,
  user: types.maybe(SellerUserModel),
  address: types.string,
});

export type SellerDetail = Instance<typeof SellerDetailModel>;

function mapSellerDetailResponseToSellerDetail(
  response: SellerDetailResponse
): SellerUser {
  return {
    username: response.User?.username,
    name: response.User?.name,
    address: response.address ?? "",
    phone: response.User.phone ?? "",
  };
}

function mapSellerAdminFeeResponseToAdminFee(
  response: AdminFeeResponse
): SellerAdminFee {
  if (!response) {
    return null;
  }

  return {
    id: response.id,
    type: response.type === "cash" ? AdminFeeType.cash : AdminFeeType.tempo,
    percentageAmount: response.percentage_amount,
    dueDays: response.due_days,
    amount: response.amount,
    active: response.active,
  };
}

/**
 * ORDER LOGS
 */
export const PriceQuantityLogModel = types.model("PriceQuantityLog").props({
  orderItem: types.maybe(OrderItemModel),
  previousPrice: types.number,
  previousQty: types.number,
  updatedPrice: types.number,
  updatedQty: types.number,
});

export const OrderLogsModel = types.model("OrderLog").props({
  outOfStockLog: types.map(types.maybe(PriceQuantityLogModel)),
  priceLog: types.map(types.maybe(PriceQuantityLogModel)),
  priceQuantityLog: types.map(types.maybe(PriceQuantityLogModel)),
  quantityLog: types.map(types.maybe(PriceQuantityLogModel)),
});

export const ReturnItemModel = types.model("ReturnItem").props({
  orderItemLogsId: types.number,
  orderItemId: types.string,
  qty: types.number,
  price: types.number,
});

export type PriceQuantityLog = Instance<typeof PriceQuantityLogModel>;
export type OrderLogs = Instance<typeof OrderLogsModel>;
export type ReturnItem = Instance<typeof ReturnItemModel>;

export function mapReturnItemResponseToReturnItems(
  response: ReturnItemResponse[]
): ReturnItem[] {
  return response.map((res) => {
    return {
      orderItemLogsId: res.order_item_logs_id,
      orderItemId: res.order_item_id,
      qty: res.qty,
      price: res.price,
    };
  });
}

function mapPriceQuantityLogObjToPriceQuantityLog(
  obj: PriceQuantityLogObj
): PriceQuantityLog {
  return {
    orderItem: mapOrderItemsResponseToOrderItems([obj.order_item])[0],
    previousPrice: obj.previous_price,
    previousQty: obj.previous_qty,
    updatedPrice: obj.updated_price,
    updatedQty: obj.updated_qty,
  };
}

function mapOrderLogs(orderLogs: PriceQuantityLogObj[]) {
  return orderLogs.reduce((obj, log) => {
    obj[log.order_item.id] = mapPriceQuantityLogObjToPriceQuantityLog(log);
    return obj;
  }, {});
}

export const OrderPaymentMethodModel = types.model("OrderPaymentMethod").props({
  id: types.string,
  name: types.string,
  active: types.boolean,
});
export type OrderPaymentMethod = Instance<typeof OrderPaymentMethodModel>;

export const OrderPaymentHistoryModel = types
  .model("OrderPaymentHistory")
  .props({
    id: types.string,
    orderId: types.string,
    paymentMethod: types.maybe(OrderPaymentMethodModel),
    amount: types.number,
    paymentUrl: types.maybeNull(types.string),
    paymentProofUrl: types.maybeNull(types.string),
    status: types.string,
    notes: types.maybeNull(types.string),
    dueDate: types.maybeNull(types.string),
    createdAt: types.string,
  });
export type OrderPaymentHistory = Instance<typeof OrderPaymentHistoryModel>;
function mapToOrderPaymentHistories(
  orderPaymentHistories: OrderPaymentHistoryObj[]
): OrderPaymentHistory[] {
  return orderPaymentHistories.map((item) => ({
    id: item.id,
    orderId: item.order_id,
    paymentMethod: {
      id: item.OrderPaymentMethod?.id,
      name: MAP_PAYMENT_HISTORY[item.OrderPaymentMethod?.name],
      active: item.OrderPaymentMethod?.active,
    },
    amount: item.amount,
    paymentUrl: item.payment_url,
    paymentProofUrl: item.payment_proof_url,
    status: item.status,
    notes: item.notes,
    dueDate: item.due_date,
    createdAt: item.created_at,
  }));
}

export const OrderModel = types.model("Order").props({
  id: types.identifier,
  totalValue: types.number,
  totalOrderValue: types.maybeNull(types.number),
  logisticFee: types.maybeNull(types.number),
  additionalFee: types.maybeNull(types.number),
  discountAmount: types.optional(types.number, 0),
  discountPercentage: types.optional(types.number, 0),
  adminFeeId: types.maybeNull(types.string),
  sellerId: types.maybeNull(types.string),
  buyerId: types.maybeNull(types.string),
  transactionNumber: types.string,
  type: types.string,
  paymentMethod: types.maybeNull(types.string),
  logisticMethod: types.maybeNull(types.string),
  customerName: types.maybeNull(types.string),
  customerNotes: types.maybeNull(types.string),
  customerPhone: types.maybeNull(types.string),
  recipientPhone: types.maybeNull(types.string),
  orderNotes: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  paymentStatus: types.string,
  acceptedAt: types.maybeNull(types.string),
  shippedAt: types.maybeNull(types.string),
  cancelledAt: types.maybeNull(types.string),
  completedAt: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  stateId: types.maybeNull(types.number),
  cityId: types.maybeNull(types.number),
  districtId: types.maybeNull(types.number),
  subdistrictId: types.maybeNull(types.number),
  cancelledReason: types.maybeNull(types.string),
  paidAt: types.maybeNull(types.string),
  deliveryType: types.maybeNull(types.string),
  deliveryman: types.maybeNull(DeliverymanModel),
  sales: types.maybe(SalesModel),
  revision: types.maybeNull(types.string),
  dueDate: types.maybeNull(types.string),
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  orderItems: types.array(OrderItemModel),
  adminFee: types.maybeNull(SellerAdminFeeModel),
  sellerDetail: types.maybe(SellerUserModel),
  voucherFee: types.maybe(types.number),
  voucherLogisticFee: types.maybe(types.number),
  voucher: types.maybeNull(OrderVoucherModel),
  coverageArea: types.maybeNull(OrderCoverageAreaModel),
  paymentLink: types.maybeNull(types.string),
  paymentLinkExpireDate: types.maybeNull(types.string),
  hasOrderLogs: types.boolean,
  hasOutOfStockLogs: types.boolean,
  orderLogs: types.maybe(OrderLogsModel),
  agreementAt: types.maybeNull(types.string),
  agreementStatus: types.maybeNull(types.string),
  morakLoanAgreementLetter: types.maybeNull(types.string),
  morakLoanStatus: types.maybeNull(types.string),
  orderPaymentHistories: types.array(OrderPaymentHistoryModel),
  totalPaymentDue: types.maybeNull(types.number),
  returnItems: types.array(OrderItemModel),
});

export type Order = Instance<typeof OrderModel>;

export type OrderMap = Map<string, Order>;

export function mapOrderResponseToOrder(response: OrderResponse): Order {
  const orderLogs = response?.OrderLogs
    ? {
        outOfStockLog: mapOrderLogs(response.OrderLogs?.out_of_stock_logs),
        priceLog: mapOrderLogs(response.OrderLogs?.price_logs),
        priceQuantityLog: mapOrderLogs(response.OrderLogs?.price_quantity_logs),
        quantityLog: mapOrderLogs(response.OrderLogs?.quantity_logs),
      }
    : null;

  const returnItemOrderItemIds = response?.return_items?.map(
    (item) => item.order_item_id
  );

  const mappedOrderItems = mapOrderItemsResponseToOrderItems(
    response.OrderItems
  );

  const orderItems = response?.OrderItems
    ? mappedOrderItems.filter((ori) => {
        // filter removed item
        if (orderLogs?.priceLog[ori?.id] && ori.deletedAt) return false;
        // filter returned item and making sure returned qty is not zero
        if (
          returnItemOrderItemIds?.length &&
          returnItemOrderItemIds.includes(ori.id) &&
          !ori.qty
        )
          return false;
        return true;
      })
    : null;

  const returnItems = response?.return_items
    ? response?.return_items.map((rri) => {
        const ori = mappedOrderItems?.find(
          (ori) => ori.id === rri.order_item_id
        );
        if (returnItemOrderItemIds.length)
          return {
            // get product data from order_item
            ...ori,
            // get qty and price from return_item
            qty: rri.qty,
            price: rri.price,
          };
      })
    : null;

  return {
    id: response.id,
    totalValue: response.total_value,
    totalOrderValue:
      response.total_value +
      (response.logistic_fee || 0) +
      (response.additional_fee || 0) -
      (response.discount_amount || 0) -
      (response.voucher_logistic_fee || 0) -
      (response.voucher_fee || 0),
    logisticFee: response.logistic_fee,
    additionalFee: response.additional_fee,
    discountAmount: response.discount_amount,
    discountPercentage: response.discount_percentage,
    sellerId: response.seller_id,
    buyerId: response.buyer_id,
    adminFeeId: response.admin_fee_id,
    type: response.type || "online",
    transactionNumber: response.transaction_number,
    paymentMethod: response.payment_method,
    logisticMethod: response.logistic_method,
    customerName: response.customer_name,
    customerNotes: response.customer_notes,
    customerPhone: response.customer_phone,
    recipientPhone: response.recipient_phone ?? "",
    address: response.address,
    orderNotes: response.order_notes,
    status: response.status,
    paymentStatus: response.payment_status,
    paymentLink: response.payment_link,
    paymentLinkExpireDate: response.will_expire_at,
    acceptedAt: response.accepted_at,
    shippedAt: response.shipped_at,
    cancelledAt: response.cancelled_at,
    completedAt: response.completed_at,
    deletedAt: response.deleted_at,
    stateId: response.state_id,
    cityId: response.city_id,
    districtId: response.district_id,
    subdistrictId: response.subdistrict_id,
    cancelledReason: response.cancelled_reason,
    paidAt: response.paid_at,
    deliveryType: response.delivery_type,
    deliveryman: null,
    sales: response.Sales || null,
    revision: null,
    dueDate: response.due_date,
    latitude: response.latitude,
    longitude: response.longitude,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    orderItems: cast(orderItems),
    adminFee: mapSellerAdminFeeResponseToAdminFee(response?.SellerAdminFee),
    sellerDetail: mapSellerDetailResponseToSellerDetail(response.SellerDetail),
    voucherFee: response.voucher_fee,
    voucherLogisticFee: response.voucher_logistic_fee,
    voucher: response.Voucher
      ? mapOrderVoucherResponseToOrderVoucher(response.Voucher)
      : null,
    coverageArea: response.CoverageArea
      ? mapOrderCoverageAreaResponseToOrderCoverageArea(response.CoverageArea)
      : null,
    hasOrderLogs:
      (!!response.OrderLogs &&
        Array.isArray(response.OrderLogs?.out_of_stock_logs) &&
        response.OrderLogs.out_of_stock_logs.length !== 0) ||
      (Array.isArray(response.OrderLogs?.price_logs) &&
        response.OrderLogs.price_logs.length !== 0) ||
      (Array.isArray(response.OrderLogs?.price_quantity_logs) &&
        response.OrderLogs.price_quantity_logs.length !== 0) ||
      (Array.isArray(response.OrderLogs?.quantity_logs) &&
        response.OrderLogs.quantity_logs.length !== 0),
    hasOutOfStockLogs:
      Array.isArray(response.OrderLogs?.out_of_stock_logs) &&
      response.OrderLogs.out_of_stock_logs.length !== 0,
    orderLogs: cast(orderLogs),
    morakLoanAgreementLetter: response.morak_loan_agreement_letter
      ? JSON.parse(response.morak_loan_agreement_letter)?.link
      : null,
    agreementAt: response.agreement_at,
    agreementStatus: response.agreement_status,
    morakLoanStatus: response.MorakLoan?.status || null,
    orderPaymentHistories: cast(
      response.OrderPaymentHistories
        ? mapToOrderPaymentHistories(response.OrderPaymentHistories)
        : []
    ),
    totalPaymentDue: response.total_payment_due || null,
    returnItems: cast(returnItems),
  };
}

export const OrderCountModel = types.model("OrderCount").props({
  totalOrder: types.number,
  totalOrderCreated: types.number,
  totalOrderAccepted: types.number,
  totalOrderShipped: types.number,
  totalOrderOnDelivery: types.number,
  totalOrderCompleted: types.number,
  totalOrderCancelled: types.number,
});

export type OrderCount = Instance<typeof OrderCountModel>;

export function mapOrderCountResultToOrderCount(
  response: OrderCountResult
): OrderCount {
  return {
    totalOrder: response.total_order ?? 0,
    totalOrderCreated: response.total_order_created ?? 0,
    totalOrderAccepted: response.total_order_accepted ?? 0,
    totalOrderShipped: response.total_order_shipped ?? 0,
    totalOrderOnDelivery: response.total_order_on_delivery ?? 0,
    totalOrderCompleted: response.total_order_completed ?? 0,
    totalOrderCancelled: response.total_order_cancelled ?? 0,
  };
}
