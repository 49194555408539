import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "../root-store";
import {
  GetBuyerAccountParams,
  PostRequestOTPResponse,
  PostVerifyOTPResponse,
} from "../../utils/interfaces";
import { ACCESS_TOKEN, REFRESH_TOKEN, setCookie } from "../../utils/cookie";
import { load, save } from "../../utils/storage";
import { USER_DATA_KEY } from "../../utils/constants";
import { normalizePhoneWithZero } from "../../utils/string";
import {
  BuyerAccount,
  mapBuyerAccountObjectToBuyerAccount,
  mapOrganicStatusObjectToOrganicStatus,
  OrganicStatus,
  UserData,
} from "./account-store.model";

class AccountStore {
  rootStore: RootStore;
  userData: UserData = null;
  buyerAccount: BuyerAccount = null;
  organicStatus: OrganicStatus = null;

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this, { rootStore: false });
  }

  protected get savedSeller() {
    return this.rootStore.sellerStore.sellerProfile;
  }

  async getUserData(): Promise<UserData> {
    const savedUser: UserData = await load(USER_DATA_KEY);
    runInAction(() => (this.userData = savedUser));
    return savedUser;
  }

  async requestOtp(
    phone: string,
    type: "wa" | "sms",
    token: string
  ): Promise<string> {
    try {
      const response: PostRequestOTPResponse =
        await this.rootStore.api.requestOtp({
          phone,
          token,
          media_type: type,
          app: process.env.NEXT_PUBLIC_APP_NAME,
        });
      if (response && response.sendtype) {
        return response.sendtype;
      }
      return "";
    } catch (error) {
      throw Error(error?.message || "Terjadi kesalahan");
    }
  }

  async verifyOtp(
    phone: string,
    sendtype: string,
    otp: string
  ): Promise<boolean> {
    try {
      const response: PostVerifyOTPResponse =
        await this.rootStore.api.verifyOtp({
          phone,
          sendtype,
          otp,
          app: process.env.NEXT_PUBLIC_APP_NAME,
          // platform 'app' for this will only be triggered from app because login is handled in app side in buyer app
          platform: "web",
        });
      if (response && response.token) {
        setCookie(ACCESS_TOKEN, response.token, response.expired_at);
        setCookie(REFRESH_TOKEN, response.refresh_token, response.expired_at);

        const buyerAccount = await this.getBuyerAccount();
        if (buyerAccount) {
          save(USER_DATA_KEY, {
            id: buyerAccount?.id,
            phone: normalizePhoneWithZero(phone),
            isTradingBuyer: buyerAccount?.trading,
          });
        }

        return true;
      }
      return false;
    } catch (error) {
      throw Error(error?.message || "Terjadi kesalahan");
    }
  }

  async getBuyerAccount(
    params: GetBuyerAccountParams = {}
  ): Promise<BuyerAccount> {
    const response = await this.rootStore.api.getBuyerAccount(params);
    let buyerAccount: BuyerAccount;
    if (response?.id) {
      buyerAccount = mapBuyerAccountObjectToBuyerAccount(response);

      // update userData storage
      if (!this.buyerAccount) {
        save(USER_DATA_KEY, {
          id: buyerAccount?.id,
          phone: normalizePhoneWithZero(buyerAccount?.phone),
          isTradingBuyer: buyerAccount?.trading,
        });
      }

      runInAction(() => (this.buyerAccount = buyerAccount));
    }
    return buyerAccount;
  }

  async getBuyerOrganicStatus(): Promise<OrganicStatus> {
    const response = await this.rootStore.api.getOrganicStatus();
    let organicStatus: OrganicStatus;
    if (response) {
      organicStatus = mapOrganicStatusObjectToOrganicStatus(response);
      runInAction(() => (this.organicStatus = organicStatus));
    }
    return organicStatus;
  }

  async getOrderVoucherCount(): Promise<number> {
    const response = await this.rootStore.api.getOrderVoucherCount();
    return response?.order_count_cash_completed_paid_voucher;
  }

  async requestMorakOtp(orderId: string) {
    try {
      await this.rootStore.api.requestMorakOtp(orderId);
    } catch (error) {
      let errorMessage = error?.message || "Terjadi kesalahan";
      if (error?.message === "Gagal mengirim OTP") {
        errorMessage =
          "Anda telah mencapai maksimum kirim ulang OTP, silahkan coba lagi dalam 24 jam";
      }
      throw Error(errorMessage);
    }
  }

  async verifyMorakOtp(orderId: string, otp: string) {
    try {
      return await this.rootStore.api.verifyMorakOtp(orderId, otp);
    } catch (error) {
      throw Error(error?.message || "Gagal melakukan verifikasi OTP");
    }
  }
}

export default AccountStore;
