import { makeAutoObservable } from "mobx";
import RootStore from "../root-store";
import {
  GetResellerCategoriesParam,
  GetSalesListData,
  GetSalesListParams,
  GetSellerCategoriesParam,
  GetSellerProfileResult,
  SellerCategoryResult,
} from "../../utils/interfaces";
import {
  DeliveryFee,
  mapAdminFeeResponseToAdminFee,
  mapDeliveryFeeObjectToDeliveryFee,
  mapSellerCategoriesResultToSellerCategories,
  mapSellerProfileResultToSellerProfile,
  Sales,
  SellerAdminFee,
  SellerCategory,
  SellerProfile,
} from "./seller-store.model";
import {
  BuyerAdminFee,
  mapBuyerPaymentMethodsResponseToBuyerAdminFee,
} from "../cart-store/cart-store.model";
import { SELLER_DATA_KEY } from "../../utils/constants";
import { storageSave, storageRemove } from "../../utils/storage";

class SellerStore {
  rootStore: RootStore;
  categories: SellerCategory[] = [];
  sellerProfile: SellerProfile = null;
  sellerSalesList: Sales[] = [];
  sellerPaymentMethods: SellerAdminFee[] = [];
  buyerPaymentMethods: BuyerAdminFee[] = [];
  deliveryFee: DeliveryFee[] = null;

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this, { rootStore: false });
  }

  setCategories(sellerCategories: SellerCategory[]) {
    this.categories = sellerCategories;
  }

  setSellerProfile(sellerProfile: SellerProfile) {
    this.sellerProfile = sellerProfile;
    if (sellerProfile) storageSave(SELLER_DATA_KEY, sellerProfile.User);
    else storageRemove(SELLER_DATA_KEY);
  }

  setSellerSalesList(salesList: Sales[]) {
    this.sellerSalesList = salesList;
  }

  setSellerPaymentMethods(paymentMethods: SellerAdminFee[]) {
    this.sellerPaymentMethods = paymentMethods;
  }

  setBuyerPaymentMethods(paymentMethods: BuyerAdminFee[]) {
    this.buyerPaymentMethods = paymentMethods;
  }

  setDeliveryFee(fee: DeliveryFee[]) {
    this.deliveryFee = fee;
  }

  async getSellerCategories(params: GetSellerCategoriesParam): Promise<void> {
    const result: SellerCategoryResult[] =
      await this.rootStore.api.getSellerCategories(params);
    if (result?.length) {
      const categories = result?.map((category) =>
        mapSellerCategoriesResultToSellerCategories(category)
      );
      this.setCategories(categories);
      return;
    }
    this.setCategories([]);
  }

  async getResellerCategories(
    params: GetResellerCategoriesParam
  ): Promise<void> {
    const result: SellerCategoryResult[] =
      await this.rootStore.api.getResellerCategories(params);
    if (result?.length) {
      const categories = result?.map((category) =>
        mapSellerCategoriesResultToSellerCategories(category)
      );
      this.setCategories(categories);
      return;
    }
    this.setCategories([]);
  }

  async getSellerProfile(
    params: GetSellerCategoriesParam
  ): Promise<SellerProfile> {
    const result: GetSellerProfileResult =
      await this.rootStore.api.getSellerProfile(params);
    if (result) {
      const sellerProfile = mapSellerProfileResultToSellerProfile(result);
      this.setSellerProfile(sellerProfile);
      return sellerProfile;
    }
  }

  async getSellerSalesList(data: GetSalesListData) {
    const params: GetSalesListParams = {
      username: data.username,
      ...(data.coverageAreaIds &&
        data.coverageAreaIds.length > 0 && {
          coverage_area_ids: data.coverageAreaIds,
        }),
    };
    const response = await this.rootStore.api.getSalesList(params);
    if (response) {
      this.setSellerSalesList(response);
    }
  }

  async getSellerPaymentMethods(username: string, coverageAreaId: string) {
    const response = await this.rootStore.api.getSellerPaymentMethods(
      username,
      coverageAreaId
    );
    if (response?.length) {
      const newSellerPaymentMethods = response
        .filter((item) => item.active)
        .map((item) => mapAdminFeeResponseToAdminFee(item));
      this.setSellerPaymentMethods(newSellerPaymentMethods);
    }
  }

  async getBuyerPaymentMethods(
    sellerId: string,
    coverageAreaId: string
  ): Promise<BuyerAdminFee[]> {
    const response = await this.rootStore.api.getBuyerPaymentMethods(
      sellerId,
      coverageAreaId
    );
    let newBuyerPaymentMethods = [];
    if (response?.length) {
      newBuyerPaymentMethods = response
        .filter((item) => item.active)
        .map((item) => mapBuyerPaymentMethodsResponseToBuyerAdminFee(item));
      this.setBuyerPaymentMethods(newBuyerPaymentMethods);
    }
    return newBuyerPaymentMethods;
  }

  async getDeliveryFees(username: string): Promise<DeliveryFee[]> {
    const response = await this.rootStore.api.getDeliveryFees(username);
    let deliveryFee: DeliveryFee[] = null;
    if (response) {
      deliveryFee = mapDeliveryFeeObjectToDeliveryFee(response);
      this.setDeliveryFee(deliveryFee);
    }
    return deliveryFee;
  }
}

export default SellerStore;
