import { makeAutoObservable } from "mobx";
import RootStore from "../root-store";
import { mapProductResponseToProduct, Product } from "./product-store.model";
import {
  GetProductsParam,
  GetResellerProductParam,
  GetResellerProductsParam,
  ProductResponse,
  ProductResult,
} from "../../utils/interfaces";

class ProductStore {
  rootStore: RootStore;
  products: Map<string, Product> = new Map();
  productsCount = 0;

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this, { rootStore: false });
  }

  get allProducts(): Product[] {
    if (!this.products) return [];
    return Array.from(this.products.values());
  }

  setProducts(_products: Map<string, Product>) {
    this.products = _products;
  }

  setProductsCount(count: number) {
    this.productsCount = count;
  }

  async getProducts(
    params: GetProductsParam,
    shouldUpdateStore = true
  ): Promise<ProductResult> {
    const result: ProductResult = await this.rootStore.api.getProducts(params);
    if (result?.product) {
      const {
        product: { rows, count },
      } = result;
      if ((Array.isArray(rows) && count !== null) || count !== undefined) {
        if (shouldUpdateStore) {
          const newProducts = new Map();
          rows?.forEach((row: ProductResponse) => {
            newProducts.set(row.id, mapProductResponseToProduct(row));
          });
          let products: Map<string, Product> = new Map(newProducts);
          if (params.offset > 0) {
            let sortableProducts = [
              ...Array.from(this.products.entries()),
              ...Array.from(newProducts.entries()),
            ];
            if (params.sort_by === "asc") {
              if (params.order_by === "price") {
                sortableProducts = sortableProducts.sort(
                  (prd1, prd2) => prd1[1].minPrice - prd2[1].minPrice
                );
              } else if (params.order_by === "name") {
                sortableProducts = sortableProducts.sort(
                  (prd1, prd2) => prd1[1].name - prd2[1].name
                );
              }
            } else if (params.sort_by === "desc") {
              if (params.order_by === "price") {
                sortableProducts = sortableProducts.sort(
                  (prd1, prd2) => prd2[1].minPrice - prd1[1].minPrice
                );
              } else if (params.order_by === "name") {
                sortableProducts = sortableProducts.sort(
                  (prd1, prd2) => prd2[1].name - prd1[1].name
                );
              }
            }
            products = new Map(sortableProducts);
          }
          this.setProductsCount(count);
          this.setProducts(products);
        }
        return result;
      }
    }
    if (shouldUpdateStore) {
      this.setProductsCount(0);
      this.setProducts(null);
    }
  }

  async getResellerProducts(
    params: GetResellerProductsParam,
    shouldUpdateStore = true
  ): Promise<ProductResult> {
    const result: ProductResult = await this.rootStore.api.getResellerProducts(
      params
    );
    if (result?.product) {
      const {
        product: { rows, count },
      } = result;
      if ((Array.isArray(rows) && count !== null) || count !== undefined) {
        if (shouldUpdateStore) {
          const newProducts = new Map();
          rows?.forEach((row: ProductResponse) => {
            newProducts.set(row.id, mapProductResponseToProduct(row));
          });
          let products: Map<string, Product> = new Map(newProducts);
          if (params.offset > 0) {
            let sortableProducts = [
              ...Array.from(this.products.entries()),
              ...Array.from(newProducts.entries()),
            ];
            if (params.sort_by === "asc") {
              if (params.order_by === "price") {
                sortableProducts = sortableProducts.sort(
                  (prd1, prd2) => prd1[1].minPrice - prd2[1].minPrice
                );
              } else if (params.order_by === "name") {
                sortableProducts = sortableProducts.sort(
                  (prd1, prd2) => prd1[1].name - prd2[1].name
                );
              }
            } else if (params.sort_by === "desc") {
              if (params.order_by === "price") {
                sortableProducts = sortableProducts.sort(
                  (prd1, prd2) => prd2[1].minPrice - prd1[1].minPrice
                );
              } else if (params.order_by === "name") {
                sortableProducts = sortableProducts.sort(
                  (prd1, prd2) => prd2[1].name - prd1[1].name
                );
              }
            }
            products = new Map(sortableProducts);
          }
          this.setProductsCount(count);
          this.setProducts(products);
        }
        return result;
      }
    }
    if (shouldUpdateStore) {
      this.setProductsCount(0);
      this.setProducts(null);
    }
  }

  async getResellerProduct(
    params: GetResellerProductParam,
    shouldUpdateStore = true
  ): Promise<ProductResult> {
    const result: ProductResult =
      await this.rootStore.api.getResellerProductDetail(params);
    if (result?.product) {
      const {
        product: { rows, count },
      } = result;
      if ((Array.isArray(rows) && count !== null) || count !== undefined) {
        if (shouldUpdateStore) {
          const _products = new Map();
          rows?.forEach((row: ProductResponse) => {
            _products.set(row.id, mapProductResponseToProduct(row));
          });
          this.setProducts(_products);
        }
        return result;
      }
    }
  }
}

export default ProductStore;
