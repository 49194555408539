import { runInAction, makeAutoObservable } from "mobx";
import {
  CART_DATA_KEY,
  NUM_VISIT_CART_PRICE_UPDATE_DATA_KEY,
  CHECKOUT_DATA_KEY,
  LAST_SUCCESS_ORDER_DATA_KEY,
  IS_AGREE_TEMPO_KEY,
} from "../../utils/constants";
import { AdminFeeType } from "../../utils/enum";
import { CreateOrderParams, ProductResult } from "../../utils/interfaces";
import { storageLoad, storageRemove, storageSave } from "../../utils/storage";
import { mapProductResponseToProduct } from "../product-store/product-store.model";
import RootStore from "../root-store";
import { Voucher } from "../voucher-store/voucher-store.model";
import {
  BuyerAdminFee,
  CartItem,
  CheckoutData,
  mapToCartItem,
} from "./cart-store.model";
import { getCookie } from "../../utils/cookie";

class CartStore {
  rootStore: RootStore;
  cartItems: CartItem[] = [];
  savedCheckoutData: CheckoutData = null;

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this, { rootStore: false });
  }

  protected get savedSeller() {
    return this.rootStore.sellerStore.sellerProfile;
  }

  protected get buyerAccount() {
    return this.rootStore.accountStore.buyerAccount;
  }

  get availableCartItems() {
    return this.cartItems.filter(
      (item) => item.isProductAvailable && item.isUnitAvailable
    );
  }

  get unavailableCartItems() {
    return this.cartItems.filter(
      (item) => !item.isProductAvailable || !item.isUnitAvailable
    );
  }

  get cartSummary() {
    let totalQty = 0;
    let totalPrice = 0;
    let totalDiscount = 0;
    let adminFee = 0;
    let appliedVoucherFee = 0;
    let appliedVoucherDeliveryFee = 0;
    let deliveryFeeEstimation = 0;
    let totalOrderPrice = 0;
    let paymentMethod: BuyerAdminFee = null;
    let voucher: Voucher = null;

    const isReseller =
      this.savedSeller?.isReseller &&
      this.savedSeller?.User?.username ===
        process.env.NEXT_PUBLIC_RESELLER_PATH;
    const isTrading = this.savedSeller?.isTrading;
    const buyerTrading = this.buyerAccount?.trading;

    const cartItems =
      this.cartItems && this.cartItems.length > 0
        ? this.cartItems.filter(
            (item) =>
              item.selected && item.isProductAvailable && item.isUnitAvailable
          )
        : [];

    totalQty = cartItems.reduce((prev, item) => {
      return prev + item.qty;
    }, 0);

    totalPrice = cartItems.reduce((prev, item) => {
      const price = item.selectedUnit?.price;
      return prev + price * item.qty;
    }, 0);

    totalDiscount = cartItems.reduce((prev, item) => {
      const price = item.selectedUnit?.price;
      const discount = item.selectedUnit?.discountPrice;
      return (
        prev + (discount && discount > 0 ? price - discount : 0) * item.qty
      );
    }, 0);

    totalOrderPrice = totalPrice - totalDiscount;

    if (this.savedCheckoutData?.paymentMethod)
      paymentMethod = this.savedCheckoutData?.paymentMethod;
    if (this.savedCheckoutData?.voucher)
      voucher = this.savedCheckoutData?.voucher;

    if (paymentMethod) {
      if (paymentMethod.percentageAmount > 0) {
        adminFee = (totalOrderPrice * paymentMethod.percentageAmount) / 100;
      } else if (paymentMethod.amount) {
        adminFee = paymentMethod.amount;
      }
    }

    if (voucher) {
      appliedVoucherFee = voucher?.appliedVoucher?.discountAmount;
      appliedVoucherDeliveryFee = voucher?.appliedVoucher?.discountDeliveryFee;
    }

    if (this.savedCheckoutData?.deliveryType === "delivery") {
      // trading
      if (isTrading || (isReseller && buyerTrading)) {
        if (paymentMethod?.type === AdminFeeType.cash) {
          const cash = this.rootStore.sellerStore?.deliveryFee?.find(
            (result) => {
              return result.seller_type === "trading" && result.type === "cash";
            }
          );
          if (cash?.active) {
            deliveryFeeEstimation = cash.amount;
          }
        } else if (paymentMethod?.type === AdminFeeType.tempo) {
          const tempo = this.rootStore.sellerStore?.deliveryFee?.find(
            (result) => {
              return (
                result.seller_type === "trading" && result.type === "tempo"
              );
            }
          );
          if (tempo?.active) {
            deliveryFeeEstimation = tempo.amount;
          }
        }
      } else {
        if (paymentMethod?.type === AdminFeeType.cash) {
          const cash = this.rootStore.sellerStore?.deliveryFee?.find(
            (result) => {
              return result.type === "cash";
            }
          );
          if (cash?.active) {
            deliveryFeeEstimation = cash.amount;
          }
        } else if (paymentMethod?.type === AdminFeeType.tempo) {
          const tempo = this.rootStore.sellerStore?.deliveryFee?.find(
            (result) => {
              return result.type === "tempo";
            }
          );
          if (tempo?.active) {
            deliveryFeeEstimation = tempo.amount;
          }
        }
      }
    }

    const totalFinalPrice =
      totalOrderPrice +
      adminFee +
      (deliveryFeeEstimation - appliedVoucherDeliveryFee) -
      appliedVoucherFee;

    return {
      totalQty,
      totalPrice,
      totalDiscount,
      adminFee,
      deliveryFeeEstimation,
      totalOrderPrice,
      totalFinalPrice,
      checkedItems: cartItems,
    };
  }

  get isAnyProductPriceChanged() {
    return this.cartItems.some((item) => item.isPriceChanged);
  }

  get minPurchase() {
    const isReseller =
      this.savedSeller?.isReseller &&
      this.savedSeller?.User?.username ===
        process.env.NEXT_PUBLIC_RESELLER_PATH;
    const isTrading = this.savedSeller?.isTrading;
    const buyerTrading = this.buyerAccount?.trading;
    // reseller
    if (isReseller && !buyerTrading) {
      return this.savedSeller?.resellerMinOrderCashLimit || 0;
    }
    // trading
    if (isTrading || (isReseller && buyerTrading)) {
      return this.savedSeller?.tradingMinOrderCashLimit || 0;
    }
    // supplier
    if (!isReseller && !isTrading) {
      return this.savedSeller?.minOrderCashLimit || 0;
    }
    return 0;
  }

  get isReachedMinPurchase() {
    return this.minPurchase > 0
      ? this.cartSummary?.totalOrderPrice > this.minPurchase
      : true;
  }

  get remainingAmountToAdd() {
    return !this.isReachedMinPurchase
      ? this.minPurchase - this.cartSummary?.totalOrderPrice
      : 0;
  }

  get selectedCoverageArea() {
    return this.rootStore.coverageAreaStore.selectedCoverageArea;
  }

  async saveCheckoutData(data: CheckoutData) {
    this.savedCheckoutData = data;
    if (this.savedSeller)
      storageSave(
        `${CHECKOUT_DATA_KEY}-${this.savedSeller?.User?.username}`,
        data
      );
  }

  async getSavedCheckoutData(): Promise<CheckoutData> {
    const savedCheckout: CheckoutData = await storageLoad(
      `${CHECKOUT_DATA_KEY}-${this.savedSeller?.User?.username}`
    );
    if (savedCheckout) this.saveCheckoutData(savedCheckout);
    return savedCheckout;
  }

  async setCartItems(items: CartItem[]) {
    let affix = "";
    if (this.savedSeller?.isReseller) {
      affix += this.selectedCoverageArea?.id
        ? `-${this.selectedCoverageArea?.id.replace("CRA-", "")}`
        : "";
      if (this.buyerAccount?.trading) {
        affix += "-trading";
      }
    }

    this.cartItems = items;
    if (
      this.savedSeller &&
      (!this.savedSeller?.isReseller ||
        (this.savedSeller?.isReseller && this.selectedCoverageArea?.id))
    ) {
      storageSave(
        `${CART_DATA_KEY}-${this.savedSeller?.User?.username}${affix}`,
        items
      );
    } else
      storageRemove(
        `${CART_DATA_KEY}-${this.savedSeller?.User?.username}${affix}`
      );
  }

  async initCart({
    shouldRefetchCartItems = false,
  }: {
    shouldRefetchCartItems?: boolean;
  } = {}) {
    let affix = "";
    if (this.savedSeller?.isReseller) {
      affix += this.selectedCoverageArea?.id
        ? `-${this.selectedCoverageArea?.id.replace("CRA-", "")}`
        : "";
      if (this.buyerAccount?.trading) {
        affix += "-trading";
      }
    }

    // load saved checkout data to calculate selected payment method & voucher in cartSummary
    await this.getSavedCheckoutData();

    const currentCart: CartItem[] =
      (await storageLoad(
        `${CART_DATA_KEY}-${this.savedSeller?.User?.username}${affix}`
      )) || [];

    // check product availability to server
    if (shouldRefetchCartItems) {
      const numCartVisit: number = await storageLoad(
        `${NUM_VISIT_CART_PRICE_UPDATE_DATA_KEY}-${this.savedSeller?.User?.username}`
      );

      const productIds = currentCart
        .filter(({ product }) => !product.referenceProductId)
        .map(({ product }) => product.id);
      const resellerProductIds = currentCart
        .filter(({ product }) => !!product.referenceProductId)
        .map(({ product }) => product.id);

      if (!productIds.length && !resellerProductIds?.length) {
        this.setCartItems([]);
        return;
      }

      const checkedProducts = new Map<string, ProductResult>();

      // reseller products
      if (resellerProductIds?.length && this.selectedCoverageArea?.id) {
        const resellerResponse = await this.rootStore.api.getResellerProducts({
          username: this.savedSeller?.User?.username,
          id: resellerProductIds.toString(),
          coverage_area_id: this.selectedCoverageArea?.id,
          limit: resellerProductIds.length,
        });
        if (
          resellerResponse?.product &&
          Array.isArray(resellerResponse?.product?.rows)
        ) {
          resellerResponse?.product?.rows.forEach(
            (product) => (checkedProducts[product.id] = product)
          );
        }
      }

      // personal or seller products
      if (productIds.length) {
        const response = await this.rootStore.api.getProducts({
          username: this.savedSeller?.User?.username,
          id: productIds.toString(),
          limit: productIds.length,
        });
        if (response?.product && Array.isArray(response?.product?.rows)) {
          response?.product?.rows.forEach(
            (product) => (checkedProducts[product.id] = product)
          );
        }
      }

      const newCartItems = currentCart.map((cartItem) => {
        const productFromServer =
          checkedProducts[cartItem.product.id] &&
          mapProductResponseToProduct(checkedProducts[cartItem.product.id]);
        const unitFromServer =
          productFromServer &&
          productFromServer.productUnits.find(
            ({ unit, minQty }) =>
              unit === cartItem.selectedUnit.unit && minQty <= cartItem.qty
          );
        const isItemPriceChanged =
          unitFromServer &&
          (unitFromServer.price !== cartItem.selectedUnit.price ||
            unitFromServer.discountPrice !==
              cartItem.selectedUnit.discountPrice);

        // check product availability
        if (!productFromServer) {
          // if product cannot be found on server, then we assume it is removed or not available
          cartItem.isProductAvailable = false;
          cartItem.selected = false;
        } else {
          cartItem.isProductAvailable = true;
          // update latest product
          cartItem.product = productFromServer;
          // check product unit availability
          if (!unitFromServer) cartItem.isUnitAvailable = false;
          else {
            cartItem.isUnitAvailable = true;
            if (isItemPriceChanged) {
              // set ticker for product price update
              cartItem.isPriceChanged = true;
              // assign previous selected unit with the current selected unit
              cartItem.previousSelectedUnit = cartItem.selectedUnit;
              // update latest unit & price
              cartItem.selectedUnit = unitFromServer;
              const prevPrice =
                cartItem.previousSelectedUnit?.discountPrice ||
                cartItem.previousSelectedUnit?.price;
              const newPrice =
                cartItem.selectedUnit?.discountPrice ||
                cartItem.selectedUnit?.price;
              cartItem.amountChanged = newPrice - prevPrice;
            }
            // reset product price update ticker, the mark will only be shown one time when buyer open the cart
            if (numCartVisit >= 1 && !isItemPriceChanged) {
              cartItem.isPriceChanged = false;
              cartItem.previousSelectedUnit = null;
              cartItem.amountChanged = null;
            }
          }
        }

        // handle selected cartItem
        if (!productFromServer || !unitFromServer) cartItem.selected = false;

        return cartItem;
      });

      // reset number of cart page visits for the second time buyer visits the cart page
      if (numCartVisit >= 1)
        storageRemove(
          `${NUM_VISIT_CART_PRICE_UPDATE_DATA_KEY}-${this.savedSeller?.User?.username}`
        );

      this.setCartItems(newCartItems);
    } else {
      this.setCartItems(currentCart);
    }
  }

  addItemToCart(cartItem: CartItem) {
    const { product, selectedUnit, qty } = cartItem;

    // check wholesale unit
    const newUnit =
      cartItem.product.productUnits.find(
        (unit) =>
          unit.unit === selectedUnit.unit &&
          (qty >= unit.minQty || !unit.minQty)
      ) || selectedUnit; // case for placeholder unavailable product unit

    const cartItemToAdd = mapToCartItem(product, newUnit, qty);

    const existingProduct = this.cartItems.find(
      (item) =>
        item.product.id === cartItemToAdd.product.id &&
        item.selectedUnit.unit === cartItemToAdd.selectedUnit.unit
    );

    let newCartItems: CartItem[] = [];
    if (existingProduct) {
      newCartItems = this.cartItems.map((item) => {
        if (
          item.product.id === cartItemToAdd.product.id &&
          item.selectedUnit.unit === cartItemToAdd.selectedUnit.unit
        ) {
          return { ...item, ...cartItemToAdd, qty: item.qty + qty };
        }
        return item;
      });
    } else {
      newCartItems = [{ ...cartItemToAdd, qty }, ...this.cartItems];
    }

    this.setCartItems(newCartItems);
  }

  updateItemToCart(cartItemIndex: number, cartItem: CartItem) {
    const { product, selectedUnit, qty } = cartItem;

    // check wholesale unit
    const newUnit = cartItem.product.productUnits.find(
      (unit) =>
        unit.unit === selectedUnit.unit && (qty >= unit.minQty || !unit.minQty)
    );
    const cartItemToUpdate = mapToCartItem(product, newUnit, qty);

    const availCartItems = this.cartItems.filter(
      (item) => item.isProductAvailable && item.isUnitAvailable
    );
    const outOfStockCartItems = this.cartItems.filter(
      (item) => !item.isProductAvailable || !item.isUnitAvailable
    );

    const newCartItems = availCartItems.filter((item, index, self) => {
      if (
        cartItemIndex === index &&
        item.product.id === cartItemToUpdate.product.id
      ) {
        // set default to false
        if (item.isPriceChanged) item.isPriceChanged = false;

        // update qty current item
        if (item.selectedUnit.unit === cartItemToUpdate.selectedUnit.unit) {
          runInAction(() => {
            // update unit current item (in case, qty meet wholesale price)
            item.selectedUnit = cartItemToUpdate.selectedUnit;
            item.qty = qty;
          });
          return true;
        }

        // update existing or current item
        if (item.selectedUnit.unit !== cartItemToUpdate.selectedUnit.unit) {
          const {
            product: { id: cartItemToUpdateId },
            selectedUnit: { unit: cartItemToUpdateUnit },
          } = cartItemToUpdate;
          const existingUnit = self.find(
            ({ product: { id }, selectedUnit: { unit } }) =>
              id === cartItemToUpdateId && unit === cartItemToUpdateUnit
          );
          if (existingUnit) {
            // update existing item with new item
            runInAction(() => Object.assign(existingUnit, cartItemToUpdate));
            return false;
          } else {
            // update current item with new item
            runInAction(() => Object.assign(item, cartItemToUpdate));
            return true;
          }
        }
      }
      return true;
    });

    this.setCartItems([...newCartItems, ...outOfStockCartItems]);
  }

  removeItemsFromCart(cartIds: string[]) {
    const newCartItems = this.cartItems.filter(
      (item) => !cartIds.includes(item.cartId)
    );
    this.setCartItems(newCartItems);
  }

  async createOrder(isFromBuyerApp = false) {
    /*
    let isAgreeTempo = null
    if (this.savedSeller?.isReseller && this.savedSeller?.User?.username === process.env.NEXT_PUBLIC_RESELLER_PATH) {
      // get selected coverage area
      if (!this.selectedCoverageArea?.id) await this.rootStore.coverageAreaStore.getSavedCoverageArea()

      // get tempo agreement
      isAgreeTempo = await storageLoad(IS_AGREE_TEMPO_KEY)
    }
    */
    let deviceId;
    try {
      deviceId = await getCookie("device_id");
    } catch (e) {
      console.log(e);
    }

    const saved = await this.getSavedCheckoutData();
    const params: CreateOrderParams = {
      items: this.cartSummary.checkedItems.map((item) => ({
        product_id: item.product.id,
        product_category_id: item.product.productCategoryId,
        price: item.selectedUnit.discountPrice || item.selectedUnit.price,
        qty: item.qty,
        unit: item.selectedUnit.unit,
        notes: null,
      })),
      customer_name: saved.customerName,
      customer_phone: saved.customerPhone,
      recipient_phone: saved.recipientPhone,
      delivery_type: saved.deliveryType,
      ...(saved.deliveryType === "delivery" && {
        address: saved.customerAddress.address,
        latitude: saved.customerAddress.latitude,
        longitude: saved.customerAddress.longitude,
        state_id: saved.customerAddress.stateId,
        city_id: saved.customerAddress.cityId,
        district_id: saved.customerAddress.districtId,
        subdistrict_id: saved.customerAddress.subdistrictId,
        zipcode: saved.customerAddress.zipcode,
        ...(saved.customerAddress.notes && {
          notes: saved.customerAddress.notes,
        }),
      }),
      ...(saved.orderNotes && {
        order_notes: saved.orderNotes,
      }),
      ...(saved.sales?.id && {
        sales_id: saved.sales.id,
      }),
      ...(saved.paymentMethod?.id && {
        admin_fee_id: saved.paymentMethod.id,
      }),
      ...(this.selectedCoverageArea?.id && {
        coverage_area_id: this.selectedCoverageArea.id,
      }),
      ...(saved.voucher?.id && {
        voucher_id: saved.voucher.id,
      }),
      // ...(isAgreeTempo && { agreement: true }),
      ...(isFromBuyerApp &&
        deviceId && {
          device_id: deviceId,
        }),
      // platform 'app' will be used if is in buyer app
      platform: isFromBuyerApp ? "app" : "web",
    };

    try {
      const response = await this.rootStore.api.createOrder(params);
      if (response && Array.isArray(response?.orders)) {
        // reset tempo agreement
        // if (isAgreeTempo !== null) storageRemove(IS_AGREE_TEMPO_KEY)

        // remove checked items from cartItems
        this.setCartItems(
          this.cartItems.filter((cartItem) => !cartItem.selected)
        );

        if (this.savedCheckoutData?.sales)
          this.saveCheckoutData({ ...this.savedCheckoutData, sales: null });
        if (this.savedCheckoutData?.paymentMethod)
          this.saveCheckoutData({
            ...this.savedCheckoutData,
            paymentMethod: null,
          });
        if (this.savedCheckoutData?.voucher)
          this.saveCheckoutData({ ...this.savedCheckoutData, voucher: null });

        // set order data to local storage
        storageSave(LAST_SUCCESS_ORDER_DATA_KEY, {
          id: response?.orders[0]?.id,
          trx: response?.orders[0]?.transaction_number,
        });
      }
      return response;
    } catch (e) {
      throw Error(e?.message || "Terjadi kesalahan");
    }
  }
}

export default CartStore;
