import { makeAutoObservable } from "mobx";
import RootStore from "../root-store";
import { CheckVoucherParams, GetVouchersParams } from "../../utils/interfaces";
import { mapVoucherObjectToVoucher, Voucher } from "./voucher-store.model";
import { CoverageArea } from "../coverage-area-store/coverage-area-store.model";

class VoucherStore {
  rootStore: RootStore;
  voucherMap: Map<string, Voucher> = new Map();
  filteredVoucher: Voucher = null;

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this, { rootStore: false });
  }

  get allVoucher(): Voucher[] {
    if (!this.voucherMap) return [];
    const allVoucher = Array.from(this.voucherMap.values());
    if (this.filteredVoucher) {
      return [
        this.filteredVoucher,
        ...allVoucher.filter((v) => v.id !== this.filteredVoucher.id),
      ];
    }
    return allVoucher;
  }

  setVoucherMap(voucherMap: Map<string, Voucher>) {
    this.voucherMap = voucherMap;
  }

  setFilteredVoucher(voucher: Voucher) {
    this.filteredVoucher = voucher;
  }

  async getVouchers(params: GetVouchersParams): Promise<any> {
    const response = await this.rootStore.api.getVouchers(params);
    if (
      response &&
      Array.isArray(response.rows) &&
      (response.count !== null || response.count !== undefined)
    ) {
      if (params.voucher_code) {
        // search voucher
        if (response.rows.length)
          this.setFilteredVoucher(mapVoucherObjectToVoucher(response.rows[0]));
      } else {
        // all voucher (inject and redeemed voucher)
        const voucherMap = new Map<string, Voucher>();
        response.rows.forEach((obj) => {
          voucherMap.set(String(obj.id), mapVoucherObjectToVoucher(obj));
        });
        this.setVoucherMap(voucherMap);
      }
    }
  }

  async checkVoucher(voucher: Voucher): Promise<Voucher> {
    const savedSeller = this.rootStore.sellerStore.sellerProfile;
    const savedData = await this.rootStore.cartStore.getSavedCheckoutData();
    const cartItems = this.rootStore.cartStore.cartSummary.checkedItems;

    let savedCoverageArea: CoverageArea = null;
    if (savedSeller?.isReseller) {
      savedCoverageArea =
        await this.rootStore.coverageAreaStore.getSavedCoverageArea();
    }

    const params: CheckVoucherParams = {
      id: voucher.id,
      username: savedSeller?.User?.username,
      admin_fee_id: savedData?.paymentMethod?.id,
      products: cartItems?.map((item) => ({
        product_category_id: item.product.productCategoryId,
        price: item.selectedUnit.discountPrice || item.selectedUnit.price,
        qty: item.qty,
      })),
      ...(savedData?.customerAddress?.placeId && {
        place_id: savedData?.customerAddress?.placeId,
      }),
      ...(savedSeller?.isReseller && {
        ...(savedCoverageArea?.id && {
          coverage_area_id: savedCoverageArea?.id,
        }),
      }),
    };

    const response = await this.rootStore.api.checkVoucher(params);
    if (response && response?.id) {
      return mapVoucherObjectToVoucher(response);
    }
  }
}

export default VoucherStore;
