import { Fragment } from "react";
import classNames from "classnames";
import { Transition } from "@headlessui/react";
import styles from "./index.module.css";

interface LoadingOverlayProps {
  visible: boolean;
}

export const LoadingOverlay = ({ visible }: LoadingOverlayProps) => {
  const renderOverlay = () => (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={classNames(styles.overlay, visible ? "z-50" : "-z-10")} />
    </Transition.Child>
  );

  return (
    <Transition appear show={visible} as={Fragment}>
      <div className={classNames(styles.container, visible ? "z-50" : "-z-10")}>
        {renderOverlay()}
        <div
          className={classNames(styles.cubeGrid, visible ? "z-50" : "-z-10")}
        >
          <div className={classNames(styles.cube, "bg-primary")}></div>
          <div className={classNames(styles.cube, "bg-primary")}></div>
          <div className={classNames(styles.cube, "bg-primary")}></div>
          <div className={classNames(styles.cube, "bg-primary")}></div>
          <div className={classNames(styles.cube, "bg-primary")}></div>
          <div className={classNames(styles.cube, "bg-primary")}></div>
          <div className={classNames(styles.cube, "bg-primary")}></div>
          <div className={classNames(styles.cube, "bg-primary")}></div>
          <div className={classNames(styles.cube, "bg-primary")}></div>
        </div>
      </div>
    </Transition>
  );
};
