import { makeAutoObservable, runInAction } from "mobx";
import {
  GetResellerDistrictsParams,
  GetCoverageAreaData,
} from "../../utils/interfaces";
import { load, save } from "../../utils/storage";
import RootStore from "../root-store";
import {
  CoverageArea,
  mapCoverageAreaObjectToCoverageArea,
  mapResellerCityObjectToResellerCity,
  mapResellerDistrictObjectToResellerDistrict,
  ResellerCity,
  ResellerDistrict,
} from "./coverage-area-store.model";
import { COVERAGE_AREA_DATA_KEY } from "../../utils/constants";
import { LocationSource } from "../../modules/seller/components/reseller-location-modal/context";

class CoverageAreaStore {
  rootStore: RootStore;
  isLocationPickerOpen = false;
  cities: ResellerCity[] = [];
  districts: ResellerDistrict[] = [];
  coverageAreas: CoverageArea[] = [];
  selectedCity: ResellerCity = null;
  selectedDistrict: ResellerDistrict = null;
  selectedCoverageArea: CoverageArea = null;

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this, { rootStore: false });
  }

  protected get savedSeller() {
    return this.rootStore.sellerStore.sellerProfile;
  }

  setLocationPickerOpen(isOpen: boolean) {
    this.isLocationPickerOpen = isOpen;
  }

  setSelectedCoverageArea(area: CoverageArea) {
    this.selectedCoverageArea = area;
    this.selectedCity = {
      id: area?.areaDistricts[0]?.cityId,
      name: area?.areaDistricts[0]?.cityName,
    };
    this.selectedDistrict = {
      id: area?.areaDistricts[0]?.districtId,
      name: area?.areaDistricts[0]?.districtName,
      cityId: this.selectedCity?.id,
      cityName: this.selectedCity?.name,
    };
    save(`${COVERAGE_AREA_DATA_KEY}-${this.savedSeller?.User?.username}`, area);
  }

  async getSavedCoverageArea(
    data?: GetCoverageAreaData
  ): Promise<CoverageArea> {
    let coverageArea: CoverageArea = null;

    // check & get data from query params
    if (data && Object.keys(data).length) {
      const { coverageAreaId, cityId, districtId, locationSource } = data;

      // enforce user to select location in reseller-location-modal
      if (!coverageAreaId || !cityId || !districtId) return;

      // refetch coverage area
      coverageArea = await this.getAndSetCoverageArea({
        coverageAreaId,
        cityId,
        districtId,
        locationSource,
      });
    }

    // check & get data from local storage
    const savedCoverageArea: CoverageArea = await load(
      `${COVERAGE_AREA_DATA_KEY}-${this.savedSeller?.User?.username}`
    );
    if ((!data || !Object.keys(data).length) && savedCoverageArea) {
      // refetch coverage area
      coverageArea = await this.getAndSetCoverageArea({
        coverageAreaId: savedCoverageArea.id,
        cityId: savedCoverageArea.areaDistricts[0].cityId,
        districtId: savedCoverageArea.areaDistricts[0].districtId,
        locationSource: savedCoverageArea.locationSource,
      });
    }

    // set init customer address
    if (data?.locationSource === LocationSource.dropdown) {
      const { latitude, longitude, cityId, districtId } = data;
      this.rootStore.cartStore.saveCheckoutData({
        ...this.rootStore.cartStore.savedCheckoutData,
        deliveryType: "delivery",
        customerAddress: {
          address: `${this.selectedCity?.name} - ${this.selectedDistrict?.name}`,
          latitude,
          longitude,
          stateId: null,
          cityId,
          districtId,
          subdistrictId: null,
          zipcode: null,
          notes: null,
          placeId: null,
        },
      });
    }
    if (data?.locationSource === LocationSource.map) {
      const {
        address,
        latitude,
        longitude,
        cityId,
        districtId,
        notes,
        placeId,
      } = data;
      this.rootStore.cartStore.saveCheckoutData({
        ...this.rootStore.cartStore.savedCheckoutData,
        deliveryType: "delivery",
        customerAddress: {
          address,
          latitude,
          longitude,
          stateId: null,
          cityId,
          districtId,
          subdistrictId: null,
          zipcode: null,
          notes,
          placeId,
        },
      });
    }
    return coverageArea;
  }

  async getCities(): Promise<ResellerCity[]> {
    const response = await this.rootStore.api.getResellerCities();
    let cities: ResellerCity[];
    if (response?.rows) {
      cities = response.rows.map((c) => mapResellerCityObjectToResellerCity(c));
      runInAction(() => (this.cities = cities));
    }
    return cities;
  }

  async getDistricts(
    params: GetResellerDistrictsParams
  ): Promise<ResellerDistrict[]> {
    const isTradingBuyer = this.rootStore.accountStore?.buyerAccount?.trading;
    params.resellerBusinessType = isTradingBuyer ? "trading" : "retail";

    const response = await this.rootStore.api.getResellerDistricts(params);
    let districts: ResellerDistrict[];
    if (response?.rows) {
      districts = response.rows.map((c) =>
        mapResellerDistrictObjectToResellerDistrict(c)
      );
      runInAction(() => (this.districts = districts));
    } else {
      runInAction(() => (this.districts = []));
    }
    return districts;
  }

  async getAndSetCoverageArea(
    data: GetCoverageAreaData
  ): Promise<CoverageArea> {
    const { coverageAreaId, cityId, districtId, locationSource } = data;
    const response = await this.rootStore.api.getCoverageArea({
      ...(coverageAreaId && { id: coverageAreaId }),
      ...(cityId && { city_id: cityId }),
      ...(districtId && { district_id: districtId }),
    });
    let selectedCoverageArea: CoverageArea = null;
    if (response?.coverage_areas?.length) {
      const coverageAreas = response.coverage_areas.map((area) =>
        mapCoverageAreaObjectToCoverageArea(area)
      );
      if (coverageAreas?.length) {
        runInAction(() => (this.coverageAreas = coverageAreas));
        selectedCoverageArea = {
          ...coverageAreas[0],
          locationSource,
        };
        this.setSelectedCoverageArea(selectedCoverageArea);
      }
    }
    return selectedCoverageArea;
  }
}

export default CoverageAreaStore;
